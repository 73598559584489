import { alertController, toastController } from '@ionic/vue';


    const openToast= async function (head, msg, tiempo) {
        const toast = await toastController
            .create({
            header: head,
            message: msg,
            duration: tiempo ===undefined ? 2000 : tiempo,
            position: 'middle',
            buttons: [
                {
                side: 'end',
                text: 'Ok'
                }
            ]
            })
        await toast.present();
        };



    const openAlerta = async function(head, msg, callback) {
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                buttons: [
                    {
                    text: 'Si',
                    handler: () => { 
                        window.fake_console('en el handler')
                        callback()
                    },
                    },
                ]
            })
        return alerta.present(); 
    }

    const openAlertaBinaria = async function(head, msg, callbackTrue) {
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                buttons: [
                    { text: 'No', handler: () => { window.fake_console('desestimamos'); } },
                    { text: 'Si', handler: () => { window.fake_console('en el handler'); callbackTrue();} },
                ]
            })
        return alerta.present(); 
    }



export { openToast , openAlerta, openAlertaBinaria }

