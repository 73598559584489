<template>
    <ion-header>
        <ion-toolbar  :color="color===undefined ? 'primary' : color " class="ion-justify-content-start ion-align-items-start"> 
            <ion-buttons slot="start" >
                    <ion-button @click="$goHome()" > 
                        <ion-icon :md="homeSharp"></ion-icon>
                    </ion-button> 
            </ion-buttons >
           <ion-title>
                <ion-row  class="ion-justify-content-start ion-align-items-start">
                    <ion-col size="40"  class="ion-float-left izquierda">
                        {{titulo}}
                    </ion-col>
                </ion-row>                
            </ion-title>
        </ion-toolbar>
    </ion-header>
</template>

<script>
    import {    IonHeader,  IonTitle, 
                IonToolbar,IonButtons,IonButton, IonIcon,
                 IonCol, IonRow,
            } from '@ionic/vue';
    import { homeSharp} from 'ionicons/icons';
    import { defineComponent } from 'vue';
    export default defineComponent({
        name : "CabeceraComponent",
        components: {  IonHeader,  IonTitle, IonButton, IonIcon,
        IonToolbar, IonButtons, 
        IonCol, IonRow },
        props : ['titulo', 'color'] , 
        data() {
            return {homeSharp }
            }, 
         /*methods : {
             goHome (){ this.$router.replace('/')}}
           */     
        
    })
</script>

<style scoped>
    ion-title {
        width: 100% !important;
        padding-right: 4px !important;
    }
    ion-row {
        width: 100% !important; 
    }
    .izquierda {
        text-align:left !important; 
    }
</style>