<template>
      <ion-page>
        <CabeceraComponent titulo="QR Escaner " color="primary"/>
        <ion-content>
            <ion-row class="fina">
                Escanea el codigo QR de la estantería para añadirlo
                a tu compra 
            </ion-row>
            <ion-row>
                <qrcode-stream @decode="onDecode"></qrcode-stream>
            </ion-row>
        </ion-content>
      </ion-page>
        
</template>

<script>
import { defineComponent } from 'vue';
import { QrcodeStream } from 'vue3-qrcode-reader'
import CabeceraComponent from '@/components/cabecera-component.vue'
import {    IonPage,  IonContent, IonRow, modalController} from '@ionic/vue';
import eventBus from "@/event-bus";
import  AddToCartOneProductComponent from '@/components/add-to-cart-one-product-component.vue'
//import eventBus from "@/event-bus";
/**
 * http://www.hellohowareyouhaveaniceday.com/blog/2019/1/13/building-a-barcode-scanner-with-vue-and-quggajs
 */
export default defineComponent({
  name: 'QrScannerPage',
  components: {QrcodeStream, CabeceraComponent, IonPage,  IonContent, IonRow}, 
    methods: {
        
            async onDecode (decodedString) {
                var self = this
                const destino = decodedString.replace(/^https:\/\/(tienda.ges.digital|localhost:3000)\/add-to-cart\/\d{3}\//g,'')
                window.fake_console(destino)
                const modal = await modalController
                    .create({
                            component: AddToCartOneProductComponent,
                            cssClass: 'custom-recommendations-modal',
                            componentProps: {
                                pdv: this.$pdv, 
                                sku: destino
                            },
                            })
                eventBus().emitter.on('cerrar-add-to-cart',()=>{
                    modal.dismiss();
                    self.$router.go(-1)
                    });
                modal.present();


                }

            
    }
});
</script>

<style scoped>
    ion-row.fina {
        padding-top: 20% !important;
        width: 80% !important; 
        height: 20% !important; 
        margin: auto !important; 

    }
    ion-row {
        padding-top: 20% !important;
        width: 80% !important; 
        height: 50% !important; 
        margin: auto !important; 
    }

</style>