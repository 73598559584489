
import { createRouter, createWebHistory } from '@ionic/vue-router';
import eventBus from "@/event-bus";
import {  loadExpire } from '@/utilities/services/storage-utilities';
import Login from '@/pages/login/login-page.vue'
import SearchResults from '@/pages/search-results/search-results-page.vue'
import qrCodePage from '@/pages/checkout/qr-code-page.vue'
import checkoutPage from '@/pages/checkout/checkout-page.vue'
import ProductPage from '@/pages/search-results/product-page.vue'
import QrScannerPage from '@/pages/search-utils/qr-scanner-page.vue'

const routes = [
  {
    path: '/',
    component: () => import ('@/pages/login/login-page.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: SearchResults, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/start/:pos/:temp_key',
    name: 'offline-login',
    props: (route) => ({ ...route.params }),
    component: Login
  },
  {
    path: '/checkout_qr',
    name: 'checkout_qr',
    props: (route) => ({ ...route.params }),
    component: qrCodePage, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: checkoutPage, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/scan-qr',
    name: 'scan-qr',
    component: QrScannerPage, 
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product/:pdv/:sku(.*)',
    name: 'ProductDetail',
    component: ProductPage, 
    props: (route) => ({ ...route.params }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-to-cart/:pdv/:sku(.*)',
    name: 'add-to-cart-one-product',
    component: SearchResults, 
    props: (route) => ({ ...route.params }),
    meta: {
      requiresAuth: true
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



/** miramos en casda caso si estamos locados con algún usuario  */
router.beforeEach(async (to, from, next) => {
  window.fake_console(from,to,next)
  const {ex,tk} = await loadExpire();
  const tm = Date.now() 
  window.fake_console(tm, ex)
  window.fake_console('Index: 93 ',ex, tk)
  window.fake_console('index86 : en el router desde: '+from.path+' hacia : '+to.path) 
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && (!ex || ex<tm) ) {
    eventBus().emitter.emit('deeplink',to);
    window.fake_console('Redireccion a login, intento pagina y no hay credencial')
    next('/');
  } 
  else {
    window.fake_console(' cualquier otro caso ')
    next();
  }
  
});


export default router
