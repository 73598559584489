<template>
    <ion-page>

          <ion-content>
            <ion-grid class="google">   


                <ion-row v-if="!pdv" class="spin ion-justify-content-center "  >
                    <ion-col  v-if="!pdv" class="spin ion-align-self-center" size="48" > 
                        <ion-spinner  v-if="!pdv"></ion-spinner>
                    </ion-col>
                </ion-row>

                <ion-row v-else class="google ion-align-items-center ion-justify-content-center">
                    <ion-button v-on:click="Login" fill="outline" 
                        size ="large" color="primary" class="login"
                        :disabled="pdv==undefined || dist>10 ||$dev_id==undefined "
                        >
                        <ion-icon slot="start" icon="assets/google.svg"/>
                        Acceder 
                    </ion-button>

                    <ion-button v-on:click="Login" fill="outline" 
                        size ="large" color="danger" class="login"
                        :enabled="pdv==undefined || dist>10 ||$dev_id==undefined "
                        >
                        
                        Login Forzado 
                    </ion-button>
                </ion-row>
              
              <ion-row v-if="pdv!=undefined && dist >-10" class="nota">
                  <ion-note style="font-size:larger;">
                      Uff! parece que estas en ningún punto de venta.<br/>
                      Debes estar en pdv para poder utilizar esta aplicación.<br/>
                      Si es un error, dirijete al mostrador y te ayudarán.
                  </ion-note> 
              </ion-row>

              <ion-row class="google ion-align-items-start ion-justify-content-center">
                <ion-img src="img/icons/android-chrome-192x192.png"/> 
              </ion-row>
              
              </ion-grid>
          </ion-content>
    </ion-page>
</template>

<script>
    import {    IonPage, IonContent, IonButton,
                IonGrid,IonRow, IonIcon, IonImg, IonNote,
                IonCol, IonSpinner
            } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { openAlerta, openToast  } from '@/utilities/services/alert-utilities';
    import eventBus from "@/event-bus";

    export default defineComponent({
        name : "Login",
        components: {  IonPage, IonContent, IonButton,
            IonGrid,IonRow, IonIcon, IonImg, IonNote,
            IonCol, IonSpinner
            },
         props: ['pos', 'temp_key'], 
         
         data() {
            return {
                pdv: undefined, 
                dist: undefined
            }
        },

        mounted() {
            if (this.pos !=undefined)
            {
                /**Login offline */
                this.pdv = this.pos 
                this.dist = 0 
                this.OffLineLogin()
            }
            else {
                /** Login online */
                this.pdv =  this.$pdv 
                this.dist =  this.$dist  
                eventBus().emitter.on('distance-checked',(d)=>{ 
                    window.fake_console('login ',d)
                    this.pdv = d.pdv 
                    this.dist = d.dist 
                    });
            }
        },
        methods: {
            async processLogin(a){
                    var self = this
                    eventBus().emitter.emit('logged-in',{'tk':a.data.tk, 'ex': a.data.ex});
                    const minutos = parseInt((a.data.ex - Date.now())/60000)
                    await openAlerta('Login realizado', 
                        `Estas conectado, tu conexión caduca en ${minutos} minutos.<br/><br/>
                        Llegado ese momento, si lo necesitas, sólo tendrás que volver a validarte de nuevo para seguir utilizando la aplicación`, 
                        function() {
                            window.fake_console('en el callback')
                            setTimeout(()=> {
                                openToast('Tiempo excecido!', 
                                    `Tu conexión ha caducado, te redirigimos a Login para que vuelvas a conectarte`, 
                                    15000)
                                self.$router.replace('/login');
                                }, ((minutos-5)*60000))
                            self.$router.replace(self.$deeplink !=undefined ? self.$deeplink : '/home') 
                            }
                        )
            },
            async Login() {
                try
                {   window.fake_console(this.$pdv)
                    const a = await this.$http.get('/start')
                    this.processLogin(a)                    
                }
                catch(err){
                    alert('Ha habido algun error...')
                }
            }, 
            async OffLineLogin() {
                try
                {   
                    const a = await this.$http.post('/offline-start',  {'temp_key':this.temp_key})
                    this.processLogin(a)   
                }
                catch(err){
                    alert('Ha habido algun error...')
                }
            }

        }
})

</script>

<style scoped>
    ion-grid.google {
        height: 70%;
        margin-top: 20%;
        margin-bottom: 10%;
        width: 90%;
    }
    ion-row.google {
        height: 50%;
    }
    ion-row.nota {
        padding : 0px !important; 
        margin: auto !important; 
        /*width: 96% !important;*/
        float: center !important
    }
</style>