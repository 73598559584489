import { Storage } from '@capacitor/storage';

    const schema = 'tienda.ges.digital:'

        const savePos = async function(pos) {
            await Storage.set({ key: schema+'pdv' , value: pos})
        }
        const loadPos = async function() {
            const {value} = await Storage.get({ key: schema+'pdv' })
            return value 
        }

        const saveExpire = async function(t,k) {
            await Storage.set({ key: schema+'ex' , value: t})
            await Storage.set({ key: schema+'tk' , value: k})
            window.fake_console(t,k)
        }
        const loadExpire = async function() {
            const e = await Storage.get({ key: `${schema}ex` })
            const t = await Storage.get({ key: `${schema}tk` })
            window.fake_console({'ex': parseInt(e.value) , 'tk': t.value})
         return {'ex': parseInt(e.value) , 'tk': t.value} 
        }


        const getCheckout = async function() {
            try 
                {
                    const { value } = await Storage.get({ key: schema+'carrito' });
                    return value === undefined ?  undefined : JSON.parse(value)
                }
            catch (ex) {
                return {}
            }
        }




        
    /** NOTA: no graba bien el primero  */
        const updateCheckout = async function(sku,units) {
            window.fake_console(sku, units)
            try 
                {
                    const { value } = await Storage.get({ key: schema+'carrito' });
                    window.fake_console(value)
                    const anterior = JSON.parse(value)
                    if (units<=0) { delete anterior[sku] } else { anterior[sku] = units }
                    if (Object.keys(anterior).length > 0)
                        {
                            await Storage.set({ key: schema+'carrito' , value: JSON.stringify(anterior)})
                        }
                }
            catch (ex) {
                const nuevo = {}
                nuevo[sku] = units;
                await Storage.set({ key: schema+'carrito' , value : JSON.stringify(nuevo) });
            }
        }

        const clearCheckout = async function() {
            try 
                {
                   await Storage.remove({ key: schema+'carrito' });
                   return true 
                }
            catch (ex) {
                   return false 
            }
        }

       


        

export { updateCheckout, getCheckout, clearCheckout, 
        loadPos, savePos , 
        saveExpire, loadExpire   
    }

