<template>
    <ion-header>
        <ion-toolbar  color="primary" class="ion-justify-content-start ion-align-items-start"> 
            <ion-buttons slot="start" >
                <ion-button @click="$goHome()" > 
                    <ion-icon :md="homeSharp"  class="barra"></ion-icon>
                </ion-button> 
            </ion-buttons >           
            <ion-title>
                <ion-row  class="ion-justify-content-start ion-align-items-start">
                    <ion-col size="40"  class="ion-float-left izquierda">
                        Checkout
                    </ion-col>
                </ion-row>                
            </ion-title>
            <!--
                <ion-buttons slot="end">
                <ion-button @click="goQR" > 
                    <ion-icon :md="qrCode" class="carrito"></ion-icon>
                </ion-button> 
            </ion-buttons>
            -->
        </ion-toolbar>
        
    </ion-header>
     <ion-content class="ion-padding ion-margin" v-if ="vacio">
        <ion-row class="ion-padding ">
                <Card >
                    <template #title style="margin: 10px;">
                        Carrito vacio!!
                    </template>
                    <template #content style="margin:10px;">
                        Tu carrito se encuentra vacio,  no hay ningun articulo activo ahora mismo
                        <br/><br/>
                            <ion-button style="float:right; margin-bottom:20px;" @click="$goHome()">Volver a pagina principal </ion-button>
                        <br/>
                    </template>
                </Card>
               
        </ion-row>

     </ion-content>
 


    <ion-content class="ion-padding">
        <ion-row class="ion-no-padding"  v-if="showme">
            <ion-col size="24" text-right>
                <h4>
                    <span  color="info">Tu carrito tiene  {{lista_articulos.length}} articulos </span>
                </h4>            
            </ion-col>
            <ion-col size="12" class="ion-justify-content-end">
                <ion-button size="small" color="darksuccess" class="borrar" @click="goQR">
                        Finalizar<br/>
                </ion-button>
            </ion-col>
            <ion-col size="12" class="ion-justify-content-end">
                <ion-button size="small" color="danger" class="borrar" @click="borrar">
                        Borrar<br/>Carrito
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row class="ion-padding-top">
            <ion-list v-if="showme" class="ion-padding-left ion-padding-right">
                        <ion-item 
                            v-for="(product,index) in lista_articulos"
                            v-bind:key="`item-${index}`"
                            class="ion-no-padding ion-no-margin">        
                            <ion-row class="ion-no-padding ion-no-margin">
                                <ion-col   class="ion-no-margin  ion-no-padding"    size="14"  @click = "ver_producto(product)">
                                    <img 
                                    :src="`${$ruta_imagen}${product['thumb'] == undefined ? 'ish/supplier/'+product['sku'].slice(0,3)+'.jpg' : product['thumb']}`"  
                                        onerror="this.src='https://storage.googleapis.com/mobigescom/product_images/no_image.jpg';" 
                                        class="producto"/>
                                    <img v-if="product['filterByPromo']" :src="`https://storage.googleapis.com/mobigescom/icons/${iconos[product['filterByPromo']]}_icon.png`" class="etiqueta"/>
                                            
                                </ion-col>
                                <ion-col size-offset="1" size="34"  class="ion-no-margin  ion-no-padding">
                                    <h3 @click = "ver_producto(product)" >{{product['sku']}}</h3>
                                    <h4 @click = "ver_producto(product)" class="nombre_cortado" text-wrap><b>{{product.shortDescription}}</b></h4>
                                    <p  @click = "ver_producto(product)" class="prod" text-wrap>de <span text-capitalize>{{product.ManufacturerName}}</span></p>
                                    <ion-row  class="ion-no-padding ion-no-margin">
                                        <ion-col class="ion-no-margin  ion-no-padding" size="42"   @click = "ver_producto(product)">
                                            <h5> 
                                                <b>
                                                    <span style="font-size:larger">
                                                        {{$filters.Entero(precios[product['sku']]['p']/1)}}
                                                    </span>
                                                    <sup>{{$filters.decimales(precios[product['sku']]['p'])}} €</sup>
                                                </b> / {{$filters.Entero(precios[product['sku']]['up'])}}  {{master[product['sku']]['um']}} 
                                                <b><i> (Tarifa)</i></b>
                                            </h5>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-icon :md="trashOutline" class="borrado" @click="borra_sku(product['sku'])" > 

                                        </ion-icon>
                                        <ion-col class="ion-no-margin  ion-no-padding" size="32"  >
                                            <p class="dispo" style="color:green!important;" >
                                                {{stock[product['sku']]['e']}}
                                                <span v-if="stock[product['sku']]['st']<3">
                                                    <br/>Ultimas unidades 
                                                </span>
                                            </p>                                    
                                        </ion-col>
                                        <ion-col class="unidades ion-no-margin  ion-no-padding  align-self-end" size="16" color="secondary">
                                            <alter-units-component :un="carrito[product['sku']]" 
                                                color="secondary"
                                                :us="master[product['sku']]['us']" 
                                                :sku="product['sku']"  
                                                :um="master[product['sku']]['um']">
                                            </alter-units-component>

                                        </ion-col>
                                    </ion-row>
                                </ion-col>
                            </ion-row>
                        </ion-item>

                



            </ion-list>
        </ion-row>
    </ion-content>
</template>


<script>
    import { IonButton, IonButtons, IonCol, IonContent,  IonHeader, IonIcon,  
            IonItem, IonList,IonRow,   IonTitle, IonToolbar, modalController
             
        } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { cart, homeSharp,qrCode, trashOutline } from 'ionicons/icons';
    import { getCheckout, loadPos, clearCheckout, updateCheckout } from '@/utilities/services/storage-utilities';
    import { openAlertaBinaria  } from '@/utilities/services/alert-utilities';
    import AlterUnitsComponent from '@/components/alter-units-component.vue';
    import RecomendadosPage from '@/pages/checkout/recomendados-page.vue'
    import eventBus from "@/event-bus";
    export default defineComponent({
        name: 'checkoutPage',
        components: { 
            IonButton, IonButtons, IonCol, IonContent,  IonHeader, IonIcon,  
            IonItem, IonList,  IonRow,   IonTitle, IonToolbar ,
            AlterUnitsComponent
            }, 
 
        async updated   () {
            window.fake_console(' updated' , this.pdv)
            const p = await loadPos()
            this.pdv = p 
            this.loadCarrito()    
        },
        async mounted() {
            const p = await loadPos()
            this.pdv = p 
            this.loadCarrito()    
            eventBus().emitter.on('cambiado',async (data)=>{ 
                window.fake_console('checkout-page 163')
                window.fake_console(data)
                await updateCheckout(data.sku,data.un)    
                window.fake_console(data.sku,data.un)      
                //window.location.reload()                      
                this.$router.resolve({})
                }); 
        },
        data() {
            return {
                cart, homeSharp,qrCode, trashOutline, 
                showme :false, 
                iconos :{'Promociones' : 'promo', 'Ofertas' :'oferta',  'Outlet': 'outlet'},
                articulos: undefined, 
                datos: [], 
                precios: undefined, 
                master: undefined, 
                stock : undefined, 
                carrito : undefined , 
                pdv: undefined , 
                vacio : false, 
                recomendaciones_num : 0 
            }
        },
        methods : {
            async loadCarrito (){
                this.showme = false
                this.carrito = await getCheckout()
                if (this.carrito!=undefined) {
                    /** intentamos parsearlo, si no lo consigue es que esta vacio */
                    const k = Object.keys(this.carrito)
                    this.articulos = k.join(' OR ')
                    this.buscar_productos()
                    let recomendados = undefined 
                    window.fake_console(this.recomendaciones_num)
                    if (this.recomendaciones_num === 0 )
                    {
                        const rec = await this.$http.post('/recomendados', {'arts':k})
                        recomendados = rec.data.length>0 ?  rec.data.join(' OR ') : undefined 
                        this.recomendaciones_num +=1
                        window.fake_console(this.recomendaciones_num)
                    }
                    if (recomendados!=undefined )
                            {                    
                                const url  = `/find-sku/${this.pdv}?q=.&fq=sku:(${recomendados})&fq=dis_${this.pdv}:0*`
                                let result = await this.$http.get(url)
                                const recomendaciones = result.data 
                                const modal = await modalController
                                    .create({
                                            component: RecomendadosPage,
                                            cssClass: 'custom-recommendations-modal',
                                            componentProps: {
                                                datos: recomendaciones
                                            },
                                            })
                                eventBus().emitter.on('cerrar-recomendaciones',()=>{
                                    modal.dismiss();
                                    this.loadCarrito()
                                    });
                                modal.present();
                                
                            }                
                }
                else {
                    /** El carrito esta vacio, mostramos mensaje  */
                    this.vacio = true
                }
            },
            async buscar_productos(){
                try 
                    {
                        const url  = `/find-sku/${this.pdv}?q=.&fq=sku:(${this.articulos})`
                        let result = await this.$http.get(url)
                        this.datos =result.data 
                        this.lista_articulos = this.datos['response']['docs']
                        this.precios = this.datos['response']['precios']
                        this.master = this.datos['response']['master']
                        this.stock = this.datos['response']['stock']
                        window.fake_console(this.lista_articulos.slice(0,10))
                        this.showme = true
                    }
                catch (er) {
                    alert('error en la carga de articulos ')
                }
            },
            async goQR () {
                window.fake_console(this.carrito)
                const checkout_data = Object.keys(this.carrito).reduce((m,v)=>{
                    const x = {}
                    x['m'] = v.slice(0,3);
                    x['a'] = v.slice(-4);
                    x['u'] = this.carrito[v]
                    x['c'] = this.stock[v]['cc']!=undefined ? 1 : 0 
                    m.push(x);
                    return m 
                } ,[])

                const confirm = await this.$http.post(`/carrito/${this.pdv}`, {'carrito':checkout_data})
                console.log (confirm.data)
                window.fake_console(checkout_data)
                this.$router.push({'name': 'checkout_qr', params:{'checkout_id': `${this.pdv}/${this.$dev_id}`}})
            }, 
            async borrar() {
                await clearCheckout()
                this.loadCarrito()
            },
            async borra_sku(sku) {
                window.fake_console(`borrando sku ${sku}`)
                var self = this
                await openAlertaBinaria(`Eliminar ${sku}`, 
                        `Seguro que quieres eliminar del carrito el articulo ${sku}??`, 
                        async function () {
                            window.fake_console('en el callback');
                            await updateCheckout(sku,0);      
                            self.loadCarrito();
                            }
                        )
            }

        }, 

        });
</script>




<style scoped>

    h3, h4, h5, p {
        font-size:smaller !important; 
        margin-top: 3px !important; 
        margin-bottom: 3px !important; 
    }
    h4.nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    img.producto{
        margin: 5px !important;
        height: 90px;
        width: 90px;
        max-width: 90px;
        max-height: 90px;
        object-fit: contain;
        margin:auto;
    }
    img.etiqueta{
        margin: 5px !important;
        position: absolute;
        left: 0px;
        top: 00px;
        z-index: 1;
        max-width: 60px;
        
    }

    .carrito {
        font-size:30px;
        padding-right:5px;
        font-weight:550;
        color: var(--ion-color-blanco) !important;
    }
    p.dispo {
        font-weight:bold  !important; 
        font-size: small !important; 
    }
    ion-icon.borrado {
        font-size: 28px !important;
        color: var(--ion-color-danger) !important;
        position: absolute; 
        top: 3rem; 
        right: 0rem;
        z-index: 99999 !important;
        }
    ion-icon.barra {
        font-size: 30px !important;
        color: var(--ion-color-blanco) !important;
    }
    ion-icon.addtocart {
        padding-top: 0px;
        padding-bottom: 5px;
        padding-right: 0px;
        padding-left: 5px;
        font-size: 30px;
        margin : 0px;
    }
    ion-item-options {
        display:grid !important;
    }
    ion-row {
        width:100% !important;
    }

    ion-col.unidades {
        padding-right: 0px; 
        margin-right: 0px; 
    }
    ion-buttons.botones {
        width:100%
    }
    ion-button.borrar {
        width: 100% !important; 
        font-weight: bold !important;
        min-height: 2.5rem !important;
        margin-top: 0px;
        margin-bottom: 0px;
    }
   .p-card-title .p-card-content {
       margin: 10px !important;
   }
</style>