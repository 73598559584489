<template>
  <ion-page>
      <CabeceraComponent titulo="Checkout Mostrador" color="primary"/>
      <ion-content class="ion-padding">
         <ion-grid>
             <ion-row class="nota">
                  <h5>
                      Gracias por visitarnos!<br/><br/>
                      Dirigete al mostrador y presenta este QR <br/>
                      Nos ayudarás a ir más rápido, evitar colas y para darte un mejor servicio 
                  </h5>    
              </ion-row>

          <ion-row  class="ion-justify-content-center ion-align-items-center">
            <ion-col v-if="carrito_id" class="ion-align-self-center">
              <qrcode-vue :value="carrito_id" :size="size" level="L" foreground="#003DA5" class="myqr"/>
            </ion-col>
          </ion-row>
        </ion-grid> 
      </ion-content>
  </ion-page>
</template>  
<script>
    import { defineComponent } from 'vue';
    import CabeceraComponent from '@/components/cabecera-component'
    import {   IonPage, IonContent,IonCol,
                  IonGrid,IonRow
            } from '@ionic/vue';
     
    import QrcodeVue from 'qrcode.vue'
    export default defineComponent({
      name : "qrCodePage",
      components: {   
            IonPage, IonContent, IonGrid,IonRow, QrcodeVue, 
            IonCol, 
            CabeceraComponent,
                  },
      props: ['checkout_id'], 
      
      data()  {
        return {
          carrito_id : undefined
        }
      },
      computed :{
          size: function () {
            return Math.min(this.$device.ancho, this.$device.largo)*0.75
          }},
      mounted() {
        this.carrito_id = this.checkout_id 
      },
      methods :  {
        /*buscalo(tx) {
          eventBus().emitter.emit('cerrar-modal-sin-resultados',{'result':1, 'tx':tx})
        }, 
        cerrar() {
           eventBus().emitter.emit('cerrar-modal-sin-resultados',{'result':0, 'tx':undefined})
        
          }*/
      }
      })
</script>
<style scoped>
  ion-grid , ion-row, ion-col
  {
    align-items: center !important;
  }

  canvas.myqr {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  ion-row.nota {
    padding : 0px !important; 
    margin: auto !important; 
    width: 90% !important;
    float: center !important
  }
  h5 {
    color:var(--ion-color-primary);
    padding-bottom: 16px !important;
  }
</style>
