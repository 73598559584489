<template>
    <ion-header>

        <ion-toolbar color="primary" style="padding-top:8px;" class="top">
            <ion-searchbar  v-model="search"
                @keyup.enter="buscar_productos()"
                @ionClear="onCancel($event)"
                autocomplete="off" autocorrect="off" 
                autocapitalize="off" spellcheck="false"
                show-clear-button="always"
                :placeholder="'Buscar productos'" 
                slot="start"
                mode = "md">
            </ion-searchbar>       
            <ion-icon :md="qrCodeOutline"  class="escanear" slot="end" @click="escanear" ></ion-icon>
        </ion-toolbar>

         
        <ion-toolbar color="primary" class="segmento">
            <ion-buttons slot="start" >
                <ion-button @click="$goHome()" > 
                    <ion-icon :md="homeSharp"  class="barra"></ion-icon>
                </ion-button> 
            </ion-buttons >     
            <ion-segment 
                v-bind:value="tipo" v-model="tipo" mode="ios" 
                @ionChange="forzar_busqueda()" color="primary"
                >
                <ion-segment-button value="pdv"  >
                    <ion-label>Solo Tienda</ion-label>
                </ion-segment-button>
                <ion-segment-button value="ges"  >
                    <ion-label>Todos</ion-label>
                </ion-segment-button>
            </ion-segment>
            <ion-buttons slot="end" class="ultimo">
                <ion-button @click="goQR" > 
                    <ion-icon :md="cart" class="barra"></ion-icon>
                </ion-button> 
            </ion-buttons>
        </ion-toolbar>
        <ion-fab  vertical="bottom" edge horizontal="end" side="fixed" v-if="busqueda && lista_articulos.length>20">
                <ion-fab-button  @click="abrir_filtros()"  color="secondary">
                    <ion-icon :md="funnel"></ion-icon>
                </ion-fab-button>
        </ion-fab>     
    </ion-header>

    <ion-content class="ion-padding">
   

        <home-help-component v-if="!busqueda && lista_articulos.length==0"></home-help-component>


        <no-search-results-spellcheck-component :busqueda="busqueda" v-if="num_alternativas === 0 && num_alternativas !=undefined "/> 
        <no-search-results-change-scope-component :resultados="num_alternativas" :busqueda="busqueda" v-if="num_alternativas > 0 && num_alternativas !=undefined "/> 

        <ion-row v-if="busqueda && lista_articulos.length>0">

            <ion-row class="ion-no-padding" v-if="etiquetas && showme">
                <ion-col size="44" text-right>
                    <p color="info">
                        Tu busqueda "{{busqueda}}" ha generado <span style="font-size:larger"><b>{{datos.response.numFound }} resultados </b></span>
                        <br/>
                        Mostrando {{Math.min(limite, lista_articulos.length)}}  {{!disableInfiniteScroll ? '. Haz scroll para cargar adicionales' : ''}}
                    </p>            
                </ion-col>
            </ion-row>


            <ion-list v-if="showme" class="ion-padding-left ion-padding-right">       
                <ion-item 
                    v-for="(product,index) in lista_articulos.slice(0,limite)"
                    v-bind:key="`item-${index}`"
                    class="ion-no-padding ion-no-margin">        
                    <ion-row class="ion-no-padding ion-no-margin">    
                        <ion-col   class="ion-no-margin  ion-no-padding"    size="14"  @click = "ver_producto(product)">
                            <img 
                            :src="`${$ruta_imagen}${product['thumb'] == undefined ? 'ish/supplier/'+product['sku'].slice(0,3)+'.jpg' : product['thumb']}`"  
                                onerror="this.src='https://storage.googleapis.com/mobigescom/product_images/no_image.jpg';" 
                                class="producto"/>
                            <img v-if="product['filterByPromo']" :src="`https://storage.googleapis.com/mobigescom/icons/${iconos[product['filterByPromo']]}_icon.png`" class="etiqueta"/>
                        </ion-col>
                        <ion-col size-offset="1" size="34"  class="ion-no-margin  ion-no-padding">
                            <ion-icon :md="logoClosedCaptioning" class="cash_carry" v-if="stock[product['sku']]['cc']!=undefined"></ion-icon>                        
                            <h3 @click = "ver_producto(product)" >{{product['sku']}}</h3>
                            <h4 @click = "ver_producto(product)" class="nombre_cortado" text-wrap><b>{{product.shortDescription}}</b></h4>
                            <p  @click = "ver_producto(product)" class="prod" text-wrap>de <span text-capitalize>{{product.ManufacturerName}}</span></p>
                            <ion-row  class="ion-no-padding ion-no-margin">
                                <ion-col class="ion-no-margin  ion-no-padding" size="40"   @click = "ver_producto(product)">
                                    <h5> 
                                        <b>
                                            <span style="font-size:larger">
                                                {{$filters.Entero(precios[product['sku']]['p']/1)}}
                                            </span>
                                            <sup>{{$filters.decimales(precios[product['sku']]['p'])}} €</sup>
                                        </b> / {{$filters.Entero(precios[product['sku']]['up'])}}  {{master[product['sku']]['um']}} 
                                        <b><i> (Tarifa)</i></b>
                                    </h5>
                                    <p class="dispo" style="color:green!important;" >
                                        {{stock[product['sku']]['e']}}
                                        <span v-if="stock[product['sku']]['st']<3">
                                            <br/>Ultimas unidades 
                                        </span>
                                    </p>
                                    <p class="ubicacion" v-if="stock[product['sku']]['cc']">
                                            Ubicación : {{stock[product['sku']]['cc']}}
                                    </p>
                                    <p class="ubicacion" v-else-if="stock[product['sku']]['pdvs']==$pdv">
                                            En almacén, pedir en mostrador
                                    </p>
                                    <p class="ubicacion" v-else>
                                            Consultar en mostrador
                                    </p>

                                </ion-col>
                                <ion-col classs="ion-no-margin  ion-no-padding  align-self-end" size="8" color="secondary">
                                    <ion-icon class="addtocart"
                                        color="secondary" :md="cart" @click="openSelect(product['sku'])">
                                    </ion-icon>                                    
                                </ion-col>
                            </ion-row>
                        </ion-col>

 
                    </ion-row>
                </ion-item>
            </ion-list>
        </ion-row>
         <ion-infinite-scroll
            v-if="lista_articulos !=undefined"
            @ionInfinite="searchNext($event)" 
            threshold="100px" 
            id="infinite-scroll"
            :disabled="disableInfiniteScroll">
                
                <ion-infinite-scroll-content
                    loading-spinner="bubbles"
                    loading-text="Cargando más datos...">
                </ion-infinite-scroll-content>
        </ion-infinite-scroll>

    </ion-content>
</template>


<script>
    import { IonButton, IonButtons, IonCol, IonContent,  IonHeader, IonIcon,  
            IonItem, IonList,IonRow, IonSearchbar,  
             IonToolbar, 
            IonInfiniteScroll, IonInfiniteScrollContent,
            IonFab, IonFabButton, 
            IonSegment, IonSegmentButton,IonLabel  ,
            popoverController, modalController
        } from '@ionic/vue';
    
    import * as _  from 'underscore';
    import { defineComponent } from 'vue';
    import { funnel , cart,barcode, homeSharp, logoClosedCaptioning ,qrCodeOutline } from 'ionicons/icons';
    import { updateCheckout } from '@/utilities/services/storage-utilities';
    import * as unidecode from 'unidecode';
    import eventBus from "@/event-bus";
    import SelectUnitsComponent from '@/components/select-units-component.vue'
    import NoSearchResultsSpellcheckComponent from '@/components/no-search-results-spellcheck-component.vue'
    import NoSearchResultsChangeScopeComponent from '@/components/no-search-results-change-scope-component.vue'
    import HomeHelpComponent from '@/components/home-help-component.vue'
    import SearchFiltersComponent from '@/components/search-filters-component.vue'
    import AddToCartOneProductComponent from '@/components/add-to-cart-one-product-component.vue'

    export default defineComponent({
        name: 'SearchResults',
        components: { 
            IonButton, IonButtons, IonCol, IonContent,  IonHeader, IonIcon,  
            IonItem, IonList,  IonRow, IonSearchbar, 
            IonToolbar, 
            IonInfiniteScroll, IonInfiniteScrollContent,
            IonFab, IonFabButton, 
            IonSegment, IonSegmentButton,IonLabel  ,
            NoSearchResultsSpellcheckComponent,
            NoSearchResultsChangeScopeComponent,
            HomeHelpComponent
                
            }, 
        props: ['pdv','sku'],
        data() {
            return {
                funnel , cart,barcode, homeSharp,logoClosedCaptioning,qrCodeOutline,
                showme :false, 
                iconos :{'Promociones' : 'promo', 'Ofertas' :'oferta',  'Outlet': 'outlet'},
                filtrados: undefined, 
                busqueda: undefined, 
                search: undefined, 
                datos: [], 
                lista_articulos : [], 
                precios: undefined, 
                master: undefined, 
                stock : undefined, 
                etiquetas: undefined, 
                limite: 20, 
                disableInfiniteScroll : false,     
                tipo: 'pdv' , 
                num_alternativas : undefined
            }
        },
        mounted (){
            window.fake_console('mounted' , this.$route)
            this.onCancel()
            this.tryToOpenProduct()
            var self = this
            eventBus().emitter.on('busqueda-abierta',()=>{ 
                self.tipo = 'ges';
                self.num_alternativas = undefined;
                self.buscar_productos()
                });   
            eventBus().emitter.on('buscar-alternativa',(alternativa)=>{ 
                self.num_alternativas = undefined;
                self.search = alternativa 
                self.buscar_productos()
                });   
            eventBus().emitter.on('cerrar-sin-resultados',()=>{ 
                self.$goHome()
                });   

        },
        updated (){
            window.fake_console('updated' , this.$route)
            this.onCancel()
            this.tryToOpenProduct()
            
        },
        methods : {
           async  tryToOpenProduct(){
                if (this.pdv !=undefined && this.sku != undefined)
                {
                    const modal = await modalController
                        .create({
                            component: AddToCartOneProductComponent,
                            cssClass: 'custom-recommendations-modal',
                            componentProps: {
                                pdv: this.pdv, 
                                sku: this.sku
                            },
                            })
                    eventBus().emitter.on('cerrar-add-to-cart',()=>{
                        modal.dismiss();
                        });
                    modal.present();}
            },
            onCancel(){
                window.fake_console('en el cancel ')
                this.busqueda = undefined; 
                this.search = undefined; 
                this.lista_articulos = []
                this.datos= undefined; 
                this.precios= undefined;
                this.master= undefined;
                this.stock = undefined;
                this.etiquetas= undefined;
                this.limite= 20; 
                this.tipo = 'pdv';
                this.disableInfiniteScroll = false;
                this.num_alternativas = undefined; 
            },
            ver_producto(producto) {
                const prod = typeof(producto)==='string' ? producto : producto['sku']
                const dest_url =`/product/${this.$pdv}/${prod}`
                window.fake_console(dest_url)
                this.$router.push(dest_url)
            },
            async searchProducts (busqueda, filtros, forzar, only_one){
                try 
                {
                    window.fake_console(this.$pdv)
                    /** Busqueda restringida a la tienda o no  */
                    const tienda = forzar === true ? '' : this.tipo =='pdv' ? `&fq=dis_${this.$pdv}:0*` :''
                    /** Solo un documento (por agilidad) o no -para cuando miramos solo facetas y categorias-  */
                    const SoloUno = only_one === true ? '&rw=1' :'' 
                    let url  = `/find-sku/${this.$pdv}?q=${busqueda}${tienda}${SoloUno}`
                    if (filtros != undefined) { 
                        window.fake_console(url+'uq='+unidecode(busqueda)+'&'+filtros);
                        url = [url,filtros].join('&')
                    }
                    else {
                        window.fake_console(url+'?q='+unidecode(busqueda));
                    }
                    window.fake_console(url)
                    return this.$http.get(url)
                }
                catch(ex) {
                    return null 
                }
            },
            async buscar_productos(filtros){
                this.busqueda = this.search
                if (this.busqueda && this.busqueda.length >=3) 
                    {
                        this.showme=false;
                        let result = await this.searchProducts(this.busqueda.toLowerCase(), filtros, false)
                        this.datos =result.data 
                        if (this.datos.response.numFound>0)
                            {                            
                            this.lista_articulos = this.datos['response']['docs']
                            this.precios = this.datos['response']['precios']
                            this.master = this.datos['response']['master']
                            this.stock = this.datos['response']['stock']                        
                            this.etiquetas = _.reduce(this.datos['facet_counts']['facet_fields'], function(memo, v  ,k) {
                                /** 
                                 * Montamos arrays por tipo de filtro con el siguiente esquema 
                                 * {"cl":"0306A","nu":178,"ch":false,"vs":false}]}
                                 * cl = clave, nu = numero de elementos , ch = checked 
                                 * si/no, vs = visto u oculto 
                                 * Este objeto es el que pasamos instanciando a los filtros 
                                */
                                let falsos =_.object(['ch','vs'], [false,true])
                                let resultados = _.chain(v).groupBy((e,i) =>  {return Math.floor(i/2)}).map((x)=>{ return _.extend(_.object(['cl','nu'],  x), falsos)}).value()
                                memo[k]= _.object(['_id','v','e'],[k,false,resultados])
                                return memo
                            } ,{})
                            this.filtrar_elementos()
                            }                           
                        /** NO ha habido resultados, verificamos si el problema es que estamos en tipo 'pdv'  */
                        else  {
                            window.fake_console( 'en el loop secundario')
                            let result2 = await this.searchProducts(this.busqueda.toLowerCase(), undefined, true, true)
                            const dt = result2.data 
                            window.fake_console(dt)
                            this.num_alternativas = dt.response.numFound
                        }
                        this.showme = true;
                    }


                }, 
            filtrar_elementos() {
                this.showme = false
                window.fake_console(this.lista_articulos.length)
                this.filtrados = this.lista_articulos.slice(0,this.limite)
                this.disableInfiniteScroll = this.lista_articulos.length < this.limite
                this.showme = true
            },
            async searchNext(ev) {
                this.limite+=10; 
                window.fake_console('en el añadir registros ') 
                window.fake_console(this.limite)
                ev.target.complete();
                this.filtrar_elementos()
            }, 
            goQR () {
                this.$router.replace('/checkout')
            }, 

            async escanear() {
                this.$router.push('/scan-qr')
            },
            async openSelect(sku){
                const popover = await popoverController
                    .create({
                        component: SelectUnitsComponent,
                        cssClass: 'my-custom-popover',
                        translucent: true,
                        componentProps : {
                            'us':  this.master[sku]['us'],
                            'um': this.master[sku]['um'] , 
                            'sku' : sku
                        }
                    })
                eventBus().emitter.on('seleccionado',(data)=>{ 
                        window.fake_console('search-results 368')
                        window.fake_console(data)
                        popover.dismiss()
                        updateCheckout(sku,data.un)                            
                    });    
                await popover.present();

                const { role } = await popover.onDidDismiss();
                window.fake_console('onDidDismiss resolved with role', role);
            },

            forzar_busqueda() {
                if (this.busqueda && this.busqueda.length >=3)  {
                    this.buscar_productos()
                }
            },
            
            async abrir_filtros(){
                var self = this;
                const popoverFiltros = await popoverController
                    .create({
                        component: SearchFiltersComponent,
                         cssClass: 'my-custom-search-filters',
                        translucent: true,
                        componentProps : {
                            'etiquetas_iniciales':  this.etiquetas,
                            'busqueda': this.busqueda ,
                            'tipo': this.tipo, 
                            'pdv': this.$pdv
                        }
                    })
                eventBus().emitter.on('filtrar-busqueda',(filtros)=>{ 
                    self.buscar_productos(filtros)    
                    popoverFiltros.dismiss()
                    });    
                await popoverFiltros.present();

                const { role } = await popoverFiltros.onDidDismiss();
                window.fake_console('onDidDismiss resolved with role', role);   

 

        }, 
    }

    });
</script>




<style scoped>
    ion-col.alto_barcode {
        height: 0.95rem !important;
        max-height: 0.95rem !important;
    }
    ion-col.ultima {
        margin-bottom: 0.6rem !important;
    }

    .barcode_label{
        font-family: 'Libre Barcode 39' !important;
        font-size: 1.6rem !important;
        text-align: left !important;
        padding-left: 16px !important;

    }
    ion-card {
        margin-inline: 0px !important;
        margin-top:8px !important;
        margin-bottom:12px !important;
        border-radius: 0px !important;
        
    } 
    ion-card-content {
        padding:0px !important;
    }
    ion-card-header {
        background-color: var(--ion-color-secondary);
        color: #fff !important;
        padding-top:5px !important;
        padding-bottom:5px !important;
      }
    h3, h4, h5, p {
        font-size:smaller !important; 
        margin-top: 3px !important; 
        margin-bottom: 3px !important; 
    }
    h4.nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    img.producto{
        margin: 5px !important;
        height: 90px;
        width: 90px;
        max-width: 90px;
        max-height: 90px;
        object-fit: contain;
        margin:auto;
    }
    img.etiqueta{
        margin: 5px !important;
        position: absolute;
        left: 0px;
        top: 00px;
        z-index: 1;
        max-width: 60px;
        
    }
    .filtro-icon {
        font-size: 40px; 
    }
    .carrito {
        font-size:30px;
        padding-right:5px;
        font-weight:550;
        color: var(--ion-color-blanco) !important;
    }
    .ojo {
        font-size:30px;
        padding-right:5px;
        font-weight:550;
        color: #FFF600	!important;
    }
    .dispo {
        font-weight:bold  !important; 
    }
    ion-icon.barra {
        /**antes 30px */
        font-size: 30px !important;
        color: var(--ion-color-blanco) !important;
    }
    
    ion-row {
        width:100% !important;
    }
    ion-searchbar {
        width: 100% !important;
        margin-left: 6px !important;
    }
    ion-icon.escanear {
        /*font-size: 60px;*/
        font-size: 60px;
        color: var(--ion-color-blanco) !important;
        float:right !important;
        padding-right:8px !important;

    }
    div.fin {
        float:right !important;
        display: inline-block;
    }
    
    ion-icon.cash_carry {
        font-size: 24px !important;
        color: green !important;
        position: absolute; 
        top: 0.3rem; 
        right: 0;
        z-index: 99999 !important;
        margin-top: 0px !important; 
        margin-right: 0px !important;
    }
    p.ubicacion {
        font-weight:bold  !important; 
        font-size: small !important; 
        color: var(--ion-color-primary) !important;
    }




    ion-icon.addtocart {
        font-size: 30px !important;
        color: var(--ion-color-secondary) !important;
        position: absolute; 
        bottom: 0.5rem; 
        right: 0rem;
        z-index: 99999 !important;
    }

ion-segment {
    width: 100%;
    margin-right:6px; 
    margin-left:6px; 

}
    ion-segment-button{
        font-size: larger !important; 
        font-weight: bold !important;
        color: var(--ion-color-blanco) !important; 
        border: 1px solid var(-ion-color-blanco) !important;
        margin-bottom: 6px !important;
}

ion-segment-button.segment-button-checked{
    color: var(--ion-color-primary) !important; 
    }
ion-toolbar.segmento {
   --max-height: 1.5rem;
   --min-height: 1rem; 
    }

ion-buttons.ultimo {
    margin-right: 60px;
}

ion-toolbar.top {
   margin-bottom: -0.4rem !important;
   padding-bottom: -0.4rem !important;
    }
</style>