<template>
    <ion-select interface ="popover" multiple="false" :value="un" mode="md" @ionChange="cambio($event)">
            <ion-select-option v-for="(ik,index ) in rango" v-bind:key="`${sku}_${index}`" :value="us*(ik+1)">
                <h5>{{$filters.Entero(us*(ik+1))}}</h5>
            </ion-select-option>
    </ion-select>
</template>

<script>
    import {   
        IonSelectOption,IonSelect
    } from '@ionic/vue';
    import eventBus from "@/event-bus";
    import { defineComponent } from 'vue';
    export default defineComponent({
            name : "AltertUnitsComponent",
            components :  {   IonSelectOption,IonSelect }, 
            data()  {
                return {
                    rango : [...Array(40).keys()]
                }
            },
            props: ['us','um','sku', 'un'], 
            methods :{
                cambio (e) {
                    eventBus().emitter.emit('cambiado', {"sku" :this.sku , "un" : e.detail.value})
                }
            }
    })
</script>

<style scoped>
    ion-select {
        --padding-end: 4px !important;
        --padding-start: 4px !important;
        margin-left : 0px !important; 
        margin-right : 0px !important; 
        margin-top: 3px !important;
        margin-bottom: 3px !important;
        padding-top : 0px !important;
        padding-bottom : 0px !important;
        width: 100% !important;
        float:right;    
    }
    ion-select::part(icon) {
        color: var(--ion-color-secondary);
        opacity: 1;
        }
    ion-select::part(text) {
        font-size: smaller; 
        font-weight: bold; 
        color: var(--ion-color-secondary);
        }
    .in-item {
        max-width: 80% !important;
        text-align: right;
    }
    
</style>