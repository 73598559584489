<template>
  <ion-page>
    <ion-header  v-if="product && showme" >
        <ion-toolbar color="secondary">
            <ion-buttons slot="start" >
                <ion-button @click="cerrar()" > 
                    <ion-icon :md="homeSharp"  class="barra"></ion-icon>
                </ion-button> 
            </ion-buttons >     
            <ion-title> {{sku}}</ion-title>

        </ion-toolbar>
        <ion-list class="ion-padding ion-margin">
            <ion-item 
                class="ion-no-padding ion-no-margin">        
                <ion-row class="ion-no-padding ion-no-margin">
                    <ion-col   class="ion-no-margin  ion-no-padding"    size="14"  @click = "ver_producto(product)">
                        <img 
                        :src="`${$ruta_imagen}${product['thumb'] == undefined ? 'ish/supplier/'+sku.slice(0,3)+'.jpg' : product['thumb']}`"  
                            onerror="this.src='https://storage.googleapis.com/mobigescom/product_images/no_image.jpg';" 
                            class="producto"/>
                        <img v-if="product['filterByPromo']" :src="`https://storage.googleapis.com/mobigescom/icons/${iconos[product['filterByPromo']]}_icon.png`" class="etiqueta"/>
                                
                    </ion-col>
                    <ion-col size-offset="1" size="34"  class="ion-no-margin  ion-no-padding">
                        <h3 @click = "ver_producto(product)" >{{sku}}</h3>
                        <h4 @click = "ver_producto(product)" class="nombre_cortado" text-wrap><b>{{product.shortDescription}}</b></h4>
                        <p  @click = "ver_producto(product)" class="prod" text-wrap>de <span text-capitalize>{{product.ManufacturerName}}</span></p>
                        <ion-row  class="ion-no-padding ion-no-margin">
                            <ion-col class="ion-no-margin  ion-no-padding" size="42"   @click = "ver_producto(product)">
                                <h5> 
                                    <b>
                                        <span style="font-size:larger">
                                            {{$filters.Entero(precio['p']/1)}}
                                        </span>
                                        <sup>{{$filters.decimales(precio['p'])}} €</sup>
                                    </b> / {{$filters.Entero(precio['up'])}}  {{master['um']}} 
                                    <b><i> (Tarifa)</i></b>
                                </h5>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-icon :md="trashOutline" class="borrado" @click="borra_sku(sku)" > 

                            </ion-icon>
                            <ion-col class="ion-no-margin  ion-no-padding" size="32"  >
                                <p class="dispo" style="color:green!important;" >
                                    {{stock['e']}}
                                    <span v-if="stock['st']<3">
                                        <br/>Ultimas unidades 
                                    </span>
                                </p>                                    
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ion-item>
        </ion-list>
    </ion-header>


    <ion-content class="ion-padding-left ion-padding-right ion-margin-left ion-margin-right ion-no-margin" v-if="product && showme">
        <ion-row class="ion-padding-left ion-padding-right ion-margin-left ion-margin-right ">
            <select-units-component  class="ion-margin"
                :us="master['us']" 
                :sku="sku"  
                :um="master['um']">
            </select-units-component>
        </ion-row>
    
    </ion-content>
  </ion-page>    
</template>


<script>
    import { IonPage, IonCol, IonContent,  IonHeader, IonIcon,  IonList,
            IonItem,  IonRow  , IonTitle, 
            IonToolbar, IonButton, IonButtons
             
        } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { cart, homeSharp,qrCode, trashOutline } from 'ionicons/icons';
    import {  updateCheckout } from '@/utilities/services/storage-utilities';
    import SelectUnitsComponent from '@/components/select-units-component.vue'
    import eventBus from "@/event-bus";
    export default defineComponent({
        name: 'AddToCartOneProductComponent',
        components: { 
            IonPage, IonCol, IonContent,  IonHeader, IonIcon,  IonList,
            IonItem,   IonRow ,SelectUnitsComponent , IonTitle, 
            IonToolbar, IonButton, IonButtons
            }, 
        props : ['pdv','sku'],
        async mounted() {
            this.pos = this.pdv === undefined ? this.$pdv : this.pdv 
            this.buscar_productos()
            eventBus().emitter.on('seleccionado',async (data)=>{ 
                window.fake_console('add-to-cart-one 99')
                window.fake_console(data)
                await updateCheckout(data.sku,data.un)    
                window.fake_console(data.sku,data.un)                      
                this.cerrar()
                }); 
        },
        data() {
            return {
                cart, homeSharp,qrCode, trashOutline, 
                showme :false, 
                iconos :{'Promociones' : 'promo', 'Ofertas' :'oferta',  'Outlet': 'outlet'},
                articulos: undefined, 
                datos: [], 
                product : undefined, 
                precio: undefined, 
                master: undefined, 
                stock : undefined, 
                pos: undefined 
                
            }
        },
        methods : {
            cerrar(){
                eventBus().emitter.emit('cerrar-add-to-cart')
            }, 
            async buscar_productos(){
                try 
                    {
                        const url  = `/find-sku/${this.pos}?q=.&fq=sku:${this.sku}`
                        window.fake_console(url)
                        let result = await this.$http.get(url)
                        this.datos =result.data 
                        const sku = this.sku
                        this.product = this.datos['response']['docs'][0]
                        this.precio = this.datos['response']['precios'][sku]
                        this.master = this.datos['response']['master'][sku]
                        this.stock = this.datos['response']['stock'][sku]
                        this.showme = true
                    }
                catch (er) {
                    alert('error en la carga de articulos ')
                }
            }

        }, 

        });
</script>




<style scoped>
    ion-title {
         margin-left: 6px; 
         width: 100%; 
         text-align: left; 
    }
    h3, h4, h5, p {
        font-size:smaller !important; 
        margin-top: 3px !important; 
        margin-bottom: 3px !important; 
    }
    h4.nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    img.producto{
        margin: 5px !important;
        height: 90px;
        width: 90px;
        max-width: 90px;
        max-height: 90px;
        object-fit: contain;
        margin:auto;
    }
    img.etiqueta{
        margin: 5px !important;
        position: absolute;
        left: 0px;
        top: 00px;
        z-index: 1;
        max-width: 60px;
        
    }
    p.dispo {
        font-weight:bold  !important; 
        font-size: small !important; 
    }
    ion-icon.borrado {
        font-size: 28px !important;
        color: var(--ion-color-danger) !important;
        position: absolute; 
        top: 3rem; 
        right: 0rem;
        z-index: 99999 !important;
        }
    ion-icon.barra {
        font-size: 30px !important;
        color: var(--ion-color-blanco) !important;
    }
    ion-icon.addtocart {
        padding-top: 0px;
        padding-bottom: 5px;
        padding-right: 0px;
        padding-left: 5px;
        font-size: 30px;
        margin : 0px;
    }
    ion-item-options {
        display:grid !important;
    }
    ion-row {
        width:100% !important;
    }

    ion-col.unidades {
        padding-right: 0px; 
        margin-right: 0px; 
    }
    ion-buttons.botones {
        width:100%
    }
    ion-button.borrar {
        width: 100% !important; 
        font-weight: bold !important;
        min-height: 2.5rem !important;
        margin-top: 0px;
        margin-bottom: 0px;
    }
   .p-card-title .p-card-content {
       margin: 10px !important;
   }
</style>