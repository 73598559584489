const dNumber = (n)=> { return n ===undefined ? 0 : n }  

const filtros_tags = {
    'filterBypline':  'Linea de productos',
    'ManufacturerName' : 'Marca', 
    'filterByPromo' : 'Situacion promocional',
    'filterByGama' : 'Gama del fabricante',
    'filterByObjeto' : 'Tipo de producto'
    }


const returnTags= function (tag) {
        let t = filtros_tags[tag]
        return tag.match(/dis_[0-9]{3}/) 
          ? 'Disponibilidad' 
          : t != undefined ?  t : tag
      }
    


export { dNumber , returnTags  }

