<template>
    <ion-content>
        <ion-toolbar color="secondary" no-padding>
                <ion-title>
                    Filtros de busqueda
                </ion-title>
        </ion-toolbar>
        <ion-toolbar  class="ion-no-padding">
                <ion-buttons slot="end">
                    <ion-button @click="lanzar_filtros('busqueda')" class="ion-padding-left ion-padding-right">
                        <span class="ion-padding-right">Aplicar filtros</span>
                    </ion-button>
                </ion-buttons>
                <p class="dispo ion-padding-left ion-padding-right" style="color:green!important;"  t>
                    <i><b>{{contador}} resultados</b> </i>
                </p>
        </ion-toolbar>
        <ion-list>
            <div v-for="(item,index ) in etiquetas" v-bind:key="index">
                <ion-list-header @click = "activar(index)" color="default" class="no_margen_bajo">
                    <ion-icon  class ="clickable ion-padding-left ion-margin-left" 
                        :md="`arrow-${item['v'] ? 'dropdown' : 'dropright'}`" slot="start" >
                    </ion-icon>	       
                    {{ returnTags(item._id) }} 
                </ion-list-header>
                <div v-if="item._id=='filterBypline'">
                    <ion-list v-for="(cat,ix) in categories" v-bind:key="ix">
                        <ion-item-divider class="double-padding" v-if="item.v">
                            <b>{{returnPlines(item._id,cat)}}</b>
                        </ion-item-divider>
                        <ion-list lines="none" v-if="item.v">
                            <ion-item v-for="(p1,ip) in filtroPlines(item['e'],cat)"  v-bind:key="ip">
                                <ion-checkbox clas="ion-padding-left ion-margin-left" 
                                    @ionChange="filtrar(item._id ,p1['cl'])"  
                                    :checked="p1['ch']"
                                    style="height:22px !important">
                                </ion-checkbox>
                                <ion-label class="ion-no-padding ion-no-margin">
                                    {{returnPlines(item._id,p1['cl'])}} ({{p1['nu']}})
                                </ion-label>
                            </ion-item>   
                        </ion-list>            			
                    </ion-list>
                </div>
                <div v-if="item._id!='filterBypline'">
                    <ion-list  lines="none" v-if="item.v">
                        <ion-item v-for="(p1,ip1) of item['e']" 
                            class="ion-padding-left ion-margin-left" 
                            v-bind:key="ip1">
                            <ion-checkbox @ionChange="filtrar(item._id ,p1['cl'])"  
                                style="height:22px !important"
                                :checked="p1['ch']"
                            ></ion-checkbox>                        
                            <ion-label  >
                                {{returnPlines(item._id,p1['cl'])}} ({{p1['nu']}})
                            </ion-label>
                        </ion-item>   
                    </ion-list>			
                </div>
            </div>
        </ion-list>
    </ion-content>

</template>

<script>
    import { defineComponent } from 'vue';
    import {    
            IonContent, IonButton,   IonList, IonItem, IonToolbar, IonButtons, 
            IonIcon, IonItemDivider, IonListHeader,IonCheckbox,IonTitle,IonLabel,
            toastController
            } from '@ionic/vue';
    import eventBus from "@/event-bus";
    import * as unidecode  from 'unidecode';
    import { returnTags } from '@/utilities/global_functions'
    import * as _  from 'underscore';
    export default defineComponent({
      name : "SearchFiltersComponent",
      components: {   
          IonContent, IonButton, IonList, IonItem, IonToolbar, IonButtons, 
        IonIcon, IonItemDivider, IonListHeader,IonCheckbox,IonTitle,IonLabel,
        },
      inject: ['$current_filters'], 
      props : ['busqueda','etiquetas_iniciales', 'tipo','pdv', 'contador_inicial'], 
      data() {
            return {
                returnTags,
                etiquetas : null,
                facetas : null,
                txt_template:null,
                categories : null, 
                contador : 0 
            }
            },
      async mounted() {
          /** 
		 * cargo como etiquetas el input que recibo al abrir este modal de la pagina de busquedas. 
		 * Es un array de diccionarios con la siguiente estructura: 
		 * {"filterBypline":{"_id":"filterBypline","v":false,"e":[{"cl":"0004A","nu":81,"ch":false,"vs":true},{"cl":"0101A","nu":1,"ch":false,"vs":true},{"cl":"0201A","nu":162,"ch":false,"vs":true},{"cl":"0202A","nu":1457,"ch":false,"vs":true},{"cl":"0204A","nu":118,"ch":false,"vs":true},{"cl":"0301A","nu":4499,"ch":false,"vs":true},{"cl":"0306A","nu":178,"ch":false,"vs":true}]},
		 * "ManufacturerName":{
		 * 	"_id":"ManufacturerName","v":false,"e":[{"cl":"Schneider merlin gerin","nu":6493,"ch":false,"vs":true},{"cl":"Cahors","nu":2,"ch":false,"vs":true},{"cl":"Claved","nu":1,"ch":false,"vs":true}]},
		 * 	"dis_001":{"_id":"dis_001","v":false,"e":[{"cl":"0 Inmediata","nu":249,"ch":false,"vs":true},{"cl":"1 Dia habil","nu":591,"ch":false,"vs":true},{"cl":"2 Dias habiles","nu":688,"ch":false,"vs":true}]},
		 * 	"filterByPromo":{"_id":"filterByPromo","v":false,"e":[{"cl":"Promociones","nu":2105,"ch":false,"vs":true},{"cl":"Outlet","nu":395,"ch":false,"vs":true}]},
		 * 	"filterByGama":{"_id":"filterByGama","v":false,"e":[{"cl":"Equipos De Medida Y Provisionales De Obra","nu":2,"ch":false,"vs":true}]},
		 *	"filterByObjeto":{"_id":"filterByObjeto","v":false,"e":[{"cl":"Interruptor automático","nu":818,"ch":false,"vs":true},{"cl":"Magnetotérmico","nu":506,"ch":false,"vs":true},{"cl":"Cofret","nu":149,"ch":false,"vs":true},{"cl":"Toma","nu":133,"ch":false,"vs":true},{"cl":"Bloque diferencial","nu":108,"ch":false,"vs":true},{"cl":"Diferencial","nu":105,"ch":false,"vs":true},{"cl":"Interruptor seccionador","nu":94,"ch":false,"vs":true},{"cl":"Puerta","nu":93,"ch":false,"vs":true},{"cl":"Disyuntor","nu":73,"ch":false,"vs":true},{"cl":"Conector","nu":69,"ch":false,"vs":true},{"cl":"Clavija aérea","nu":65,"ch":false,"vs":true},{"cl":"Transformador de corriente","nu":64,"ch":false,"vs":true},{"cl":"Dispositivo de corriente residual","nu":62,"ch":false,"vs":true},{"cl":"Placa de montaje","nu":62,"ch":false,"vs":true},{"cl":"Placa frontal","nu":62,"ch":false,"vs":true},{"cl":"Extremo del cable","nu":59,"ch":false,"vs":true},{"cl":"Marcador","nu":57,"ch":false,"vs":true},{"cl":"Tapa","nu":52,"ch":false,"vs":true},{"cl":"Bornero","nu":46,"ch":false,"vs":true},{"cl":"Contactor","nu":42,"ch":false,"vs":true},{"cl":"Bastidor básico","nu":41,"ch":false,"vs":true},{"cl":"Bobina de disparo","nu":41,"ch":false,"vs":true},{"cl":"Conector móvil","nu":41,"ch":false,"vs":true},{"cl":"Interruptor diferencial","nu":41,"ch":false,"vs":true},{"cl":"Conexión posterior","nu":39,"ch":false,"vs":true}]}}
		 */
			/** 
			 * Definimos la variable txt_template, será la que utilicemos para construir la la url de busqueda con filtros 
			 * Y la variable filtro  
			 */
			this.txt_template = _.template('fq=<%= CLAVE %>%3A%28<%= VALOR %>%29')
			this.actualizar_etiquetas(this.etiquetas_iniciales)
            this.contador = this.contador_inicial
      },
      methods :  {
        activar(i) {
          window.fake_console(i)
          // Mostrar-ocultar el detalle de elementos de un grupo de filtros
          this.etiquetas[i]['v'] = this.etiquetas[i]['v']== undefined ? true : !this.etiquetas[i]['v']
        },
        filtrar(clave, valor) {
                //window.fake_console(this.$current_filters[clave], clave, valor)
                if (this.$current_filters[clave]==undefined)
                        { this.$current_filters[clave] = [valor] }
                else if( this.$current_filters[clave].indexOf(valor)!=-1)
                    { this.$current_filters[clave]= _.without(this.$current_filters[clave],valor) }
                else  
                    { this.$current_filters[clave].push(valor) }	
                if (this.$current_filters[clave].length==0) 
                        { delete this.$current_filters[clave] }
                    this.lanzar_filtros('facetas')
        }, 
        returnPlines(fam,cod) {
                return fam!='filterBypline' ? cod : this.$plines[cod]
            },
        filtroPlines(plines,cat){
                return _.filter(plines, function(v) {return v['cl'].slice(0,2)==cat})
            }, 
        actualizar_etiquetas(etiquetas_iniciales){
                /** 
                 * Marcamos como seleccionados los filtros que ya venian marcados 
                 * que son los que esten incluidos en $current_filters
                 * 
                */
                _.each(this.$current_filters, (fv  , fk )=> {
                    _.each(etiquetas_iniciales[fk]['e'], (ev,ei) => {
                            if (fv.indexOf(ev['cl'])!=-1 ) {
                                etiquetas_iniciales[fk]['e'][ei]['ch'] = true
                            }
                        })
                    })
                this.etiquetas = _.values(etiquetas_iniciales)
                
                /**
                 * En caso de que no exista inicializamos la variable global current_filters  
                 */
                if(this.$current_filters == undefined) {
                    //eventBus().emitter.emit('update-filters',{}) 
                    this.$current_filters = {}
                    }
                /** 
                 * En el caso de los filtros de pline (vienen a nivel pline -5 digitos-)
                 * generamos los agrupadores a dos digitos (Cartegorias)
                 */
                let ps = etiquetas_iniciales['filterBypline']['e']
                this.categories = _.uniq(_.map(ps, function(v) { return v['cl'].slice(0,2) }))
            }, 
        lanzar_filtros(caso) {
                let queryFilters = _.compact(_.map(this.$current_filters, (v,k)=> {
                    /*if (this.single.sistema=='ios') {
                        v = _.map(v, (ve)=> {return encodeURI(ve)})
                    }*/
                    // asi iba con android 7+
                    //return this.txt_template({CLAVE: k, VALOR: '"'+v.join('" OR "')  +'"'})
                    return this.txt_template({CLAVE: k, VALOR: '%22'+v.join('%22%20OR%20%22')  +'%22'})
                }))
                let applyFilter = queryFilters.length >0 ? queryFilters.join('&') : undefined
                if (caso == 'facetas')
                    { this.actualizar_contador_facetas(applyFilter)}			
                else {
                    eventBus().emitter.emit('filtrar-busqueda', applyFilter)
                    }
            }, 
        async actualizar_contador_facetas (applyFilter){
            /**
             * Version que utilizamos, 
             * en esta se actualiza el contador. En el caso de que llegue a cero muestra una alerta distinta 
             */
            const datos = await  this.DinamicFilters(this.busqueda, this.tipo, applyFilter)
            let nuevos_datos  =  datos.data
            this.contador = nuevos_datos['response']['numFound']
            let formato = this.contador == 0 ? 'no-results-toast' : 'recommendations-toast'
            let mens = this.contador == 0 
                    ? 'Esta combinación no tiene resultados, deselecciona alguna opción' 
                    : this.contador +' resultados , tras este filtro ' 
            let duracion = this.contador == 0 ? 1500 : 750
            const toast = await toastController
                .create({
                    message: mens,
                    position: 'middle',
                    duration: duracion,
                    /* 
                    showCloseButton: true,
                    closeButtonText: 'Ok', 
                    */
                    cssClass: formato
                });
            toast.present(); 
            
        }, 

        DinamicFilters(busqueda, tipo,filtros){
                try 
                {
                    /** Busqueda restringida a la tienda o no  */
                    const tienda = tipo =='pdv' ? `&fq=dis_${this.pdv}:0*` :''
                    let url  = `/find-sku/${this.pdv}?q=${busqueda}&rw=1${tienda}`
                    window.fake_console(url)
                    if (filtros != undefined) { 
                        window.fake_console(filtros)
                        window.fake_console('dentro del if ')
                        window.fake_console(url+'uq='+unidecode(busqueda)+'&'+filtros);
                        url = [url,filtros].join('&')
                        window.fake_console(url)
                    }
                    else {
                        window.fake_console('dentro del else')
                        window.fake_console(url+'?q='+unidecode(busqueda));
                    }
                    window.fake_console('a la salida')
                    window.fake_console(url)
                    return this.$http.get(url)
                }
                catch(ex) {
                    return null 
                }
            }
      }, 

      })

</script>

<style scoped lang="scss">
         
    .my-custom-search-filters .popover-content {
        ion-content{
            border: solid 1px gainsboro;
        }
        ion-list-header {
            margin-bottom:0px;
            margin-top:0px;
            font-style:bold;
            font-size: 1.2rem; 
        }
        .dispo {
            font-weight:bold  !important; 
        }
        ion-list-header.no_margen_bajo {
            margin-bottom:0 px !important;
        }
        ion-item-divider.double-padding{
            padding-left:25px !important;
            font-size: 1.1rem; 
        }
        ion-item {
            /*--min-height: 20px;*/
            font-size: small !important;
            margin-bottom:0px !important;
            margin-top:0px !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;        
        }
        ion-label {
                font-size: small !important;
                margin-bottom:0px !important;
                margin-top:0px !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;        
        }
        ion-list {
            font-size: small !important;
            margin-bottom:0px !important;
            margin-top:0px !important;
            padding-top: 0px !important;
            padding-bottom: 0px !important;

        }
    }
</style>
