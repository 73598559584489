<template>
     <ion-content class="ion-padding" >
          <h3 class="ion-padding-left"  >Busqueda sin resultados </h3>
          <div class="ion-padding-left ion-text-justify">
              <h5 >
              Tu busqueda <strong><i>'{{busqueda}}'</i></strong> no ha producido resultados.<br/><br/>
              Has pedido que solo te devolvamos resultados de productos que 
              estén en stock en este punto de venta ahora mismo. <br/><br/>


              Hay {{resultados}} resultados de productos en nuestro catalogo que ahora mismo no se encuentran en el pdv. <br/><br/>
              Si quiere verlos haz click en el boton adjunto. <br/>
              </h5>
          </div>
          <ion-grid class="ion-padding-right">
              <ion-row>
                <ion-col size="24"></ion-col>
                <ion-col size="24">
                    <ion-button expand="block" size="large" fill="solid" class="ion-padding" @click="cerrar()">
                        Buscar sin<br/>restrinccion
                    </ion-button>
                </ion-col>
              </ion-row>
        </ion-grid> 
      </ion-content>
</template>  
<script>
    import { defineComponent } from 'vue';
    import {    IonContent, IonButton, IonGrid,IonRow,   IonCol
            } from '@ionic/vue';
    import eventBus from "@/event-bus";
    export default defineComponent({
      name : "NoSearchResultsChangeScopeComponent",
      components: {   IonContent, IonButton, IonGrid,IonRow,    IonCol},
      props : ['busqueda', 'resultados'], 

      methods :  {
        cerrar() {
           eventBus().emitter.emit('busqueda-abierta')
          }, 
      }
      })
</script>

<style scoped>
  ion-button {
    padding: 4px !important; 
    font-weight: bold;
  }
</style>