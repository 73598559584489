<template>
    <ion-header>

        <ion-toolbar color="primary" class="segmento">
            
        <ion-buttons slot="end">
            <ion-button @click="cerrar()"  fill="outline" class="cierre"> 
                Cerrar
                <ion-icon :md="closeCircleOutline" class="barra" ></ion-icon>
            </ion-button> 
        </ion-buttons>


        </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
            <ion-row class="ion-padding" v-if="lista_articulos">
                <ion-label class="normal">
                    Estos artículos estan relacionados con el/los que has elegido. 
                    Revisa la lista, tal vez este el siguiente articulo que quieres añadir.
                    <br/>
                </ion-label>            
            </ion-row>

            <ion-list v-if="showme" class="ion-padding-left ion-padding-right">       
                <ion-item 
                    v-for="(product,index) in lista_articulos"
                    v-bind:key="`item-${index}`"
                    class="ion-no-padding ion-no-margin">        
                    <ion-row class="ion-no-padding ion-no-margin">    
                        <ion-col   class="ion-no-margin  ion-no-padding"    size="14"  @click = "ver_producto(product)">
                            <img 
                            :src="`${$ruta_imagen}${product['thumb'] == undefined ? 'ish/supplier/'+product['sku'].slice(0,3)+'.jpg' : product['thumb']}`"  
                                onerror="this.src='https://storage.googleapis.com/mobigescom/product_images/no_image.jpg';" 
                                class="producto"/>
                            <img v-if="product['filterByPromo']" :src="`https://storage.googleapis.com/mobigescom/icons/${iconos[product['filterByPromo']]}_icon.png`" class="etiqueta"/>
                        </ion-col>
                        <ion-col size-offset="1" size="34"  class="ion-no-margin  ion-no-padding">
                            <ion-icon :md="logoClosedCaptioning" class="cash_carry" v-if="stock[product['sku']]['cc']!=undefined"></ion-icon>                        
                            <h3 @click = "ver_producto(product)" >{{product['sku']}}</h3>
                            <h4 @click = "ver_producto(product)" class="nombre_cortado" text-wrap><b>{{product.shortDescription}}</b></h4>
                            <p  @click = "ver_producto(product)" class="prod" text-wrap>de <span text-capitalize>{{product.ManufacturerName}}</span></p>
                            <ion-row  class="ion-no-padding ion-no-margin">
                                <ion-col class="ion-no-margin  ion-no-padding" size="40"   @click = "ver_producto(product)">
                                    <h5> 
                                        <b>
                                            <span style="font-size:larger">
                                                {{$filters.Entero(precios[product['sku']]['p']/1)}}
                                            </span>
                                            <sup>{{$filters.decimales(precios[product['sku']]['p'])}} €</sup>
                                        </b> / {{$filters.Entero(precios[product['sku']]['up'])}}  {{master[product['sku']]['um']}} 
                                        <b><i> (Tarifa)</i></b>
                                    </h5>
                                    <p class="dispo" style="color:green!important;" >
                                        {{stock[product['sku']]['e']}}
                                        <span v-if="stock[product['sku']]['st']<3">
                                            <br/>Ultimas unidades 
                                        </span>
                                    </p>
                                    <p class="ubicacion" v-if="stock[product['sku']]['cc']">
                                            Ubicación : {{stock[product['sku']]['cc']}}
                                    </p>
                                    <p class="ubicacion" v-else-if="stock[product['sku']]['pdvs']==$pdv">
                                            En almacén, pedir en mostrador
                                    </p>
                                    <p class="ubicacion" v-else>
                                            Consultar en mostrador
                                    </p>

                                </ion-col>
                                <ion-col classs="ion-no-margin  ion-no-padding  align-self-end" size="8" color="secondary">
                                    <ion-icon class="addtocart"
                                        color="secondary" :md="cart" @click="openSelect(product['sku'])">
                                    </ion-icon>                                    
                                </ion-col>
                            </ion-row>
                        </ion-col>

 
                    </ion-row>
                </ion-item>
            </ion-list>
        


    </ion-content>
</template>


<script>
    import { IonButton, IonButtons, IonCol, IonContent,  IonHeader, IonIcon,  
            IonItem, IonList,IonRow,  
             IonToolbar, IonLabel,
            popoverController
        } from '@ionic/vue';
    
    import { defineComponent } from 'vue';
    import {  cart,logoClosedCaptioning, closeCircleOutline } from 'ionicons/icons';
    import { updateCheckout } from '@/utilities/services/storage-utilities';
    import eventBus from "@/event-bus";
    import SelectUnitsComponent from '@/components/select-units-component.vue'
    

    export default defineComponent({
        name: 'RecomendadosPage',
        components: { 
            IonButton, IonButtons, IonCol, IonContent,  IonHeader, IonIcon,  
            IonItem, IonList,  IonRow, IonLabel,
            IonToolbar, 
                
            }, 
 
        props : {datos: Object},
        data() {
            return {
                cart, logoClosedCaptioning,closeCircleOutline,
                showme :false, 
                iconos :{'Promociones' : 'promo', 'Ofertas' :'oferta',  'Outlet': 'outlet'},
                articulos: undefined, 
                lista_articulos: undefined, 
                precios: undefined, 
                master: undefined, 
                stock : undefined, 
                carrito : undefined , 
                pdv: undefined , 
                vacio : false, 
            }
        },
        mounted (){
            window.fake_console('mounted' , this.$route)
            this.buscar_productos()

        },
        updated (){
            window.fake_console('updated' , this.$route)            
            this.buscar_productos()
        },
        methods : {
            cerrar() {
                window.fake_console('cerrando...')
                eventBus().emitter.emit('cerrar-recomendaciones');   
            },
            ver_producto(producto) {
                const prod = typeof(producto)==='string' ? producto : producto['sku']
                const dest_url =`/product/${this.$pdv}/${prod}`
                window.fake_console(dest_url)
                this.$router.push(dest_url)
            },
            async buscar_productos(){
                    try 
                        {

                            this.lista_articulos = this.datos['response']['docs']
                            this.precios = this.datos['response']['precios']
                            this.master = this.datos['response']['master']
                            this.stock = this.datos['response']['stock']
                            window.fake_console(this.lista_articulos.slice(0,10))
                            this.showme = true
                        }
                    catch (er) {
                        alert('error en la carga de articulos ')
                    }
                }, 
            async openSelect(sku){
                const popover = await popoverController
                    .create({
                        component: SelectUnitsComponent,
                        cssClass: 'my-custom-popover',
                        translucent: true,
                        componentProps : {
                            'us':  this.master[sku]['us'],
                            'um': this.master[sku]['um'] , 
                            'sku' : sku
                        }
                    })
                eventBus().emitter.on('seleccionado',(data)=>{ 
                        popover.dismiss()
                        updateCheckout(sku,data.un)                            
                    });    
                await popover.present();

                const { role } = await popover.onDidDismiss();
                window.fake_console('onDidDismiss resolved with role', role);
            },
            
           
    }

    });
</script>




<style scoped>
    ion-label.normal {
        color: var(--ion-color-medium);
        font-size: 1.3rem !important; 
    }
    ion-col.alto_barcode {
        height: 0.95rem !important;
        max-height: 0.95rem !important;
    }
    ion-col.ultima {
        margin-bottom: 0.6rem !important;
    }

    .barcode_label{
        font-family: 'Libre Barcode 39' !important;
        font-size: 1.6rem !important;
        text-align: left !important;
        padding-left: 16px !important;

    }
    ion-card {
        margin-inline: 0px !important;
        margin-top:8px !important;
        margin-bottom:12px !important;
        border-radius: 0px !important;
        
    } 
    ion-card-content {
        padding:0px !important;
    }
    ion-card-header {
        background-color: var(--ion-color-secondary);
        color: #fff !important;
        padding-top:5px !important;
        padding-bottom:5px !important;
      }
    h3.normal {
        font-size: normal !important; 
    }
    h3, h4, h5, p {
        font-size:smaller !important; 
        margin-top: 3px !important; 
        margin-bottom: 3px !important; 
    }
    h4.nombre_cortado {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    img.producto{
        margin: 5px !important;
        height: 90px;
        width: 90px;
        max-width: 90px;
        max-height: 90px;
        object-fit: contain;
        margin:auto;
    }
    img.etiqueta{
        margin: 5px !important;
        position: absolute;
        left: 0px;
        top: 00px;
        z-index: 1;
        max-width: 60px;
        
    }
    .filtro-icon {
        font-size: 40px; 
    }
    .carrito {
        font-size:30px;
        padding-right:5px;
        font-weight:550;
        color: var(--ion-color-blanco) !important;
    }
    .ojo {
        font-size:30px;
        padding-right:5px;
        font-weight:550;
        color: #FFF600	!important;
    }
    .dispo {
        font-weight:bold  !important; 
    }
    ion-icon.barra {
        /**antes 30px */
        margin-left: 12px;
        font-size: 30px !important;
        color: var(--ion-color-blanco) !important;
        float:right; 
    }
    
    ion-row {
        width:100% !important;
    }
    ion-searchbar {
        width: 100% !important;
        margin-left: 6px !important;
    }
    ion-icon.escanear {
        /*font-size: 60px;*/
        font-size: 60px;
        color: var(--ion-color-blanco) !important;
        float:right !important;
        padding-right:8px !important;

    }
    div.fin {
        float:right !important;
        display: inline-block;
    }
    
    ion-icon.cash_carry {
        font-size: 24px !important;
        color: green !important;
        position: absolute; 
        top: 0.3rem; 
        right: 0;
        z-index: 99999 !important;
        margin-top: 0px !important; 
        margin-right: 0px !important;
    }
    p.ubicacion {
        font-weight:bold  !important; 
        font-size: small !important; 
        color: var(--ion-color-primary) !important;
    }




    ion-icon.addtocart {
        font-size: 30px !important;
        color: var(--ion-color-secondary) !important;
        position: absolute; 
        bottom: 0.5rem; 
        right: 0rem;
        z-index: 99999 !important;
    }

ion-segment {
    width: 100%;
    margin-right:6px; 
    margin-left:6px; 

}
    ion-segment-button{
        font-size: larger !important; 
        font-weight: bold !important;
        color: var(--ion-color-blanco) !important; 
        border: 1px solid var(-ion-color-blanco) !important;
        margin-bottom: 6px !important;
}

ion-segment-button.segment-button-checked{
    color: var(--ion-color-primary) !important; 
    }
ion-toolbar.segmento {
   --max-height: 1.5rem;
   --min-height: 1rem; 
    }

ion-buttons.ultimo {
    margin-right: 6px;
}

ion-toolbar.top {
   margin-bottom: -0.4rem !important;
   padding-bottom: -0.4rem !important;
    }
ion-button.cierre {
    min-width: 9rem !important; 
    font-weight: bold;
}
</style>