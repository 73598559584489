<template>
    <ion-content class="ion-padding" v-if="showme">
        <ion-toolbar class="ion-no-padding" color="blanco" >
            <ion-buttons slot="end">
                <ion-button @click="cerrar()" class="ion-padding-left ion-padding-right" 
                    fill="solid" color="primary">
                    <ion-icon :md="close" class="ion-padding"></ion-icon>
                    <span class="ion-padding-right">Cerrar</span>
                </ion-button>
            </ion-buttons>   
        </ion-toolbar>

       <h3 class="ion-padding-left ion-padding-right" >{{ficha_producto.sku}}</h3>
        <ion-row class="ion-no-padding" >
            <ion-col size="20" class="ion-align-self-center">
                <img 
                    :src="`${$ruta_imagen}${ficha_producto['gcs_img_h'] == undefined ? ('ish/supplier/'+ficha_producto['ges_marca']+'.jpg' ) : ('product_images/'+ficha_producto['mx_mkid']+'/'+ficha_producto['gcs_img_h'])}`"  
                    onerror="this.src='https://storage.googleapis.com/mobigescom/product_images/no_image.jpg';" 
                    class="producto"/>
            </ion-col>
            <ion-col size="28" class="ion-align-self-start">
                <h4 class="ion-text-wrap pequenyo">
                    <b>
                        {{ficha_producto['mx_lg_d']!=undefined ? ficha_producto['mx_lg_d'] : ficha_producto['ges_nombre'] }}
                    </b>
                </h4>
                <p  class="marcar ion-text-wrap">de <span class="ion-text-capitalize">{{ficha_producto['ges_desmar']}}</span></p>
                    <!-- 
                        <h5 class="precio"  v-if="!show_tarifa"> <b><span style="font-size:larger">
                        {{$filters.Entero(ficha_producto['pr']/1)}}</span>
                        <sup>{{$filter.decimales(ficha_producto['pr'])}} €</sup>
                        </b> / {{$filters.Entero(ficha_producto['us'])}}  {{ficha_producto['um']}}
                    </h5>
                    -->
                    <h5 class="precio"  > <b><span style="font-size:larger">
                        {{$filters.Entero(details['p']/1)}}</span>
                        <sup>{{$filters.decimales(details['p'])}} €</sup>
                        </b> / {{$filters.Entero(details['us'])}}  {{details['um']}} 
                        <b><i> (Tarifa)</i></b>
                    </h5>

                <p class="dispo" >{{ficha_producto['d']}}</p>
                <p class="google" @click="go2Google()" >Buscarlo en Google </p>
            </ion-col>

        </ion-row>
        <ion-row class="ion-no-padding ion-align-items-end">
            <ion-col size="20"  class="ion-align-self-end">
                <ion-buttons slot="end">
                    <ion-button fill="solid" color="secondary" @click="add2Cart(ficha_producto)">
                        <ion-icon :md="cart"></ion-icon>
                        <span  class="ion-padding-left ion-padding-right"><small>Añadir</small></span>
                    </ion-button>
                </ion-buttons>                    
            </ion-col>
            <ion-col size="28"  class="ion-padding-right">
                <ion-item class="ion-no-padding ion-no-margin" >
                    <ion-label position="floating" class="ion-no-margin" style="font-size:larger !important;" >
                        <h2 class="ion-no-margin" style="font-size:larger !important;">Cantidad</h2>
                    </ion-label>
                    <ion-select interface ="popover" multiple="false" :value="un" mode="md" @ionChange="cambio($event)">
                        <ion-select-option :value="details['us']*(ik+1)" v-for="(ik,index) in rango" v-bind:key="ficha_producto['_id']+'_'+index">
                                {{$filters.Entero(details['us']*(ik+1))}}  {{details['um']}}
                        </ion-select-option>
                    </ion-select> 
                </ion-item>

            </ion-col>
        </ion-row>

        <ion-row>
            <ion-segment v-model="tabber">
                <ion-segment-button value="descrip">
                    Descripcion
                </ion-segment-button>
                <ion-segment-button value="caracters">
                    Caracteristicas
                </ion-segment-button>
                <ion-segment-button value="stocks">
                    Stocks
                </ion-segment-button>
            </ion-segment>            
        </ion-row>

        <ion-row   v-if="tabber=='descrip'">
            <h5 class="descriptor">Descripcion comercial</h5>
            <ul class="tecnicas"  v-if="ficha_producto['mx_c_url'] ||ficha_producto['mx_t_url']">
                <li  v-if="ficha_producto['mx_c_url']" class="list_tec">
                    <a  href="#" @click="navegacion_externa($event, ficha_producto['mx_c_url'])" >
                        Información producto (fabricante)
                    </a>
            </li>
                <li  v-if="ficha_producto['mx_t_url']"  class="list_tec">
                    <a  href="#" @click="navegacion_externa($event, ficha_producto['mx_t_url'])">
                        Documentacion técnica</a>
                </li>
            </ul>
            <p class="ion-padding-left ion-padding-right ion-padding-top">{{ficha_producto['mx_cm_d']}}</p>
        </ion-row>

        <ion-row  v-if="tabber=='caracters'">
            <h5 size="48" class="ion-padding">Caracteristicas tecnicas</h5>
            <ion-list class="ion-no-padding ion-no-margin caracteristicas" size="48">
                <ion-row v-for="(cr,index) in caracteristicas" class="ion-no-padding ion-no-margin ion-no-lines" v-bind:key="index">
                    <ion-list-header class="ion-margin-bottom ion-padding-left caracteristicas" 
                        color="light">
                        {{cr['i']}} 
                        </ion-list-header>
                        <ion-row class="ion-no-padding ion-no-margin pequenyo"  v-for="(c,ic) in cr['v']" v-bind:key="ic">
                            <ion-col size="28" class="listado ion-no-margin">
                                <div style="white-space: normal !important">
                                    {{c['n']}}                        
                                </div>
                            </ion-col>
                            <ion-col size="20" class="listado ion-no-margin">
                                <div style="white-space: normal !important">
                                    {{c['v']}}
                                </div>
                            </ion-col>
                        </ion-row>
                </ion-row>
            </ion-list>
        </ion-row>



        <ion-row  v-if="tabber=='stocks'">
                <h5 class="ion-padding">Stocks disponibles</h5>
        </ion-row>
        <ion-row   v-if="tabber=='stocks'">
                <ion-list class="ion-no-padding ion-no-margin" >
                    <ion-list-header   class="lista-stocks">
                        <ion-row>
                            <ion-col size="8" class="ion-no-padding ion-no-margin ion-text-left" ><strong>Pdv</strong></ion-col>
                            <ion-col size="28" class="ion-no-padding ion-no-margin ion-text-center" ><strong>Disponibilidad</strong></ion-col>
                            <ion-col size="12" class="ion-no-padding ion-no-margin ion-text-right"><strong>Uds.</strong></ion-col>                    
                        </ion-row>
                    </ion-list-header>
                    <!--<ion-list-header v-for="(st,index) in  details['stock']" v-bind:key="'st-'+index" class="ion-no-lines lista-stocks">-->
                        <ion-row   v-for="(st,index) in  details['stock']" v-bind:key="'st-'+index" class=" estrecho ion-no-lines lista-stocks">
                            <ion-col size="8" class="listado ion-no-margin ion-text-left" >{{st['pdvs']}}</ion-col>
                            <ion-col size="28" class="listado ion-no-margin ion-text-left" >
                                <div style="white-space: normal !important">
                                    {{st['e']}}
                                </div>
                            </ion-col>
                            <ion-col size="12" class="listado ion-no-margin ion-text-right">
                                {{$filters.Entero(st['st'])}}
                            </ion-col>
                        </ion-row>
                </ion-list>
           
        </ion-row>
    </ion-content>
</template>

<script>

    import {    IonContent,  IonList,IonListHeader, IonRow, IonCol, IonSegment,IonSegmentButton, 
                IonToolbar,IonButtons,IonButton,IonIcon, 
                IonLabel, IonItem, IonSelect, IonSelectOption, 
            } from '@ionic/vue';
    import { cart, close} from 'ionicons/icons';
    import * as _ from 'underscore';
    import { Browser } from '@capacitor/browser';
    import { defineComponent } from 'vue';
    import {   updateCheckout  } from '@/utilities/services/storage-utilities';
    export default defineComponent({
        name : "ProductPage",
        components: { IonContent,  IonList,IonListHeader, IonRow, IonCol, IonSegment,IonSegmentButton, 
                IonToolbar,IonButtons,IonButton,IonIcon,
                IonLabel, IonItem, IonSelect, IonSelectOption },
        props :['sku', 'pdv'], 
        data() {
            return {
                cart, close , 
                ficha_producto : undefined, 
                details : undefined, 
                control_visual : null,
                tabber  : 'descrip', 
                caracteristicas :   null,
                rango : [...Array(40).keys()], 
                un : 0 , 
                showme :  false

                }
            }, 

        async mounted (){
            this.tabber = 'descrip'
            const product = await this.$http.get(`/product/${this.sku}`)
            this.ficha_producto = product.data;      
            const detail = await this.$http.get(`/stock/${this.pdv}/${this.sku}`)
            this.ficha_producto['qx']= detail.data['us']
            this.details = detail.data
            this.caracteristicas = _.chain(this.ficha_producto['mx_features'])
                .sortBy('og')
                .groupBy('g')
                .reduce(function(memo, v,k) {memo.push(_.object(['i','v'],[k,v])); return memo},[]).value()
            this.showme = true
            }
            , 
        methods :{
            async go2Google(){
                let terminos =_.chain(
                    [this.sku.slice(4) ,this.ficha_producto['ges_desmar'].split(' '), 
                    this.ficha_producto['mx_ean'], this.ficha_producto['ges_ean'], 
                        (this.ficha_producto['mx_lg_d']!=undefined ? this.ficha_producto['mx_lg_d'] : this.ficha_producto['ges_nombre'] ) .split(' ') ])
                    .flatten()
                    .compact()
                    .uniq()
                    .value().join(' ').toLocaleLowerCase()
                await Browser.open({ url: 'https://www.google.es/search?q='+terminos });
            }, 
            async navegacion_externa(event, link ){
                event.preventDefault()
                const direccion  = ('http://'+link).replace('http://http','http')
                await Browser.open({ url: direccion});
            }, 
            cerrar() { 
                this.$router.go(-1);
            },
            async cambio (e) {
                window.fake_console('search-results-page 224')
                window.fake_console(e)

                await updateCheckout(this.sku,e.detail.value)    
                }

        }
    })

</script>

<style scoped>
    .pequenyo {
        font-size:smaller !important;
    }
    img.producto{
        height: 130px;
        width: 130px;
        max-width: 130px;
        max-height: 130px;
        object-fit: contain;
        margin:auto;
    }
    ion-select, ion-list, ion-row {
        width: 100% !important;
    }
    .descriptor {
        padding-left: 16px;
        padding-top: 16px;
        padding-right: 16px;
        padding-bottom: 0px;
    }
    .tecnicas {
        margin-top:0px;
        margin-bottom:0px;
    }
    .list_tec{
        font-size:larger;
        padding-bottom:5px;
    }
    ion-list-header.lista-stocks{
        font-size:bold !important;
        margin-bottom:0px !important;
        min-height:36px;
        max-height:36px;
    }
    ion-list-header.caracteristicas{
        /*font-weight:bold !important;*/
        font-size: 18px !important;
        margin-bottom:6px !important;
        min-height:28px;
        max-height:28px;
    }
    
    ion-row.estrecho{
        margin-bottom:0px !important;
        margin-top:0px !important;
    }
    .item-inner {
        border:0px !important;
    }
    ion-col.listado {
        padding-left: 12px !important;
    }
    ion-list-header {
        min-height: 20px;
        max-height: 25px;
        width: 100% !important;
    } 
    ion-list.caracteristicas {
        width:90%;
        margin:auto;
    }
    ion-row, ion-item {
        width: 100% !important;
    }
    .dispo {
        color:green !important;
        font-weight:bold  !important; 
        padding-bottom:0px;
    }


    p.dispo {
        color:green !important;
        font-weight:bold  !important; 
        padding-bottom:0px;
        margin-bottom:5px !important;
    }
    p.google {
        color:orange !important;
        font-weight:bold  !important; 
        padding-bottom:0px;
        margin-bottom:5px !important;
    }
    
    h5.precio {
        margin-top:8px !important;
        margin-bottom:8px !important;
    }
    p.marca {
        margin-top:8px !important;
        margin-bottom:8px !important;
    }

    button.texto_sin_cambios ion-button.texto_sin_cambios {
        text-transform:none !important
    }
    ion-toolbar {
        border-bottom: 2px solid var(--ion-color-light)
    }
</style>