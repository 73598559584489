<template>  

            <ion-card class="ion-no-padding" >
                <ion-card-header>
                     Busca lo  que necesitas 
                </ion-card-header>
                <ion-card-content no-padding no-margin>
                    <ion-row>
                        <ion-col size="12">
                             <img src="/assets/buscar.png">
                        </ion-col>
                        <ion-col size="36" class="grande">
                            Busca un articulo por su nombre, descripción, ean.....  
                        </ion-col>
                        
                    </ion-row>
                </ion-card-content>
            </ion-card>

            <ion-card class="ion-no-padding"  >
                <ion-card-header>
                    Centrate en lo que te quieres llevar ya.
                </ion-card-header>
                <ion-card-content no-padding no-margin>
                    <ion-row>
                        <ion-col size="12">
                             <img src="/assets/elegir.png">
                        </ion-col>
                        <ion-col size="36" class="grande">
                            Quieres buscar todos los articulos del catálogo o sólo los que tenemos ahora en esta tienda?   
                        </ion-col>
                        
                    </ion-row>
                </ion-card-content>
            </ion-card>

            <ion-card class="ion-no-padding" >
                <ion-card-header>
                    Refina tu busqueda 
                </ion-card-header>
                <ion-card-content no-padding no-margin>
                    <ion-row>
                        <ion-col size="12" class="  ion-justify-content-center">
                            <ion-icon :md="funnelOutline"/>
                        </ion-col>
                        <ion-col size="36" class="grande">
                            Filtra los resultados por marcas, disponibilidad, gamas de fabricantes...  
                        </ion-col>
                    </ion-row>
                </ion-card-content>
            </ion-card>
            
            <ion-card class="ion-no-padding" >
                <ion-card-header>
                    Añadelos a tu carrito
                </ion-card-header>
                <ion-card-content no-padding no-margin>
                    <ion-row>
                        <ion-col size="12"  class="ion-align-self-center">
                            <ion-icon :md="qrCodeOutline"/>
                        </ion-col>
                        <ion-col size="36" class="grande">
                            Escanea el QR de la estantería para añadir el articulo a tu compra 
                        </ion-col>
                    </ion-row>
                </ion-card-content>
            </ion-card>

            <ion-card class="ion-no-padding" >
                <ion-card-header>
                    Agiliza tu paso por caja
                </ion-card-header>
                <ion-card-content no-padding no-margin>
                    <ion-row>
                        <ion-col size="12"  class="ion-align-self-center">
                            <ion-icon :md="cartOutline"/>
                        </ion-col>
                        <ion-col size="36" class="grande">
                            Ayudanos, generando un codigo podremos pasar tu compra por caja mucho más rápido.  
                        </ion-col>
                    </ion-row>
                </ion-card-content>
            </ion-card>


</template>


<script>
    import {  
            IonCard,IonCardContent,IonCardHeader, IonIcon, IonRow, IonCol
           } from '@ionic/vue';
    import { funnelOutline , cartOutline,qrCodeOutline } from 'ionicons/icons';

    import { defineComponent } from 'vue';
    
    export default defineComponent({
        name: 'HomeHelpComponent',
        components: {  IonCard,IonCardContent,IonCardHeader, IonIcon,IonRow, IonCol}, 
        data() {
            return {
                funnelOutline , cartOutline,  qrCodeOutline
            }
        },
        });
</script>



<style scoped>
    ion-card {
        margin-inline: 0px !important;
        margin-top:8px !important;
        margin-bottom:12px !important;
        border-radius: 0px !important;
        

        
    } 
    ion-card-content {
        padding-top:0px !important;
        padding-bottom: 2px !important;
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
    ion-card-header {
        background-color: var(--ion-color-blanco);
        font-size: larger !important;
        font-weight: bold !important;  
        color: var(--ion-color-medium);
        padding-top:4px !important;
        padding-bottom:4px !important;
      }
    ion-icon {
        font-size: 4rem !important;
        color: var(--ion-color-primary) !important;
        margin: auto;
    }
    img {
        width: 7rem; 
    }
    ion-col.grande {
        font-size: 1.2rem; 
    }

</style>