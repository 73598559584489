import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import eventBus from "@/event-bus";
import {globalFilters} from './utilities/filters'

import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import DropDown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Card from 'primevue/card';


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/** Importamos los css de primevue */
import 'primevue/resources/themes/mdc-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';


import axios from 'axios';
import { Device } from '@capacitor/device';
import { savePos, saveExpire } from '@/utilities/services/storage-utilities';


/* Theme variables */
import './theme/variables.css';
import './registerServiceWorker'

window.miconsola =   console.log 
window.fake_console =   function() {void(0)}

//axios.defaults.baseURL = 'http://localhost:3000'
axios.defaults.baseURL = 'https://tienda.ges.services'
axios.defaults.headers["Content-Type"] = "application/json"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
axios.mode = "no-cors"


/** 
 * Logica de la aplicación 
 * El router no se inicializa hasta que tenemos 
 * un cambio de AuthState en firebase
 * Sino monta las paginas antes de tener el usuario 
 * */

let app = createApp(App)
          .use(IonicVue)
          .use(PrimeVue)
          .use(router);

app.component('Card',Card);
app.component('InputText',InputText);
app.component('MultiSelect',MultiSelect);
app.component('Button',Button);
app.component('DropDown',DropDown);
app.component('InputNumber',InputNumber);

/**
 * Variables globales 
 */
var self = app.config.globalProperties
self.$http = axios
self.$pdv = undefined
self.$device = undefined
self.$filters = globalFilters
self.$deeplink = undefined 
self.$ruta = 'https://storage.googleapis.com/mobigescom/ish/banners/';
self.$ruta_imagen =  'https://storage.googleapis.com/mobigescom/';
self.$dev_id = undefined 
self.$dist = undefined 
self.$expires = undefined 
self.$plines = undefined 
app.provide('$current_filters', undefined )




/**
 * Variable global para acceder a Firebase 
 * desde el conjunto de la aplicación 
 * sino acaba intentando instanciarla varias veces
 */
window.fake_console('main ','RUTAS : '+JSON.stringify(self.$router.routes))
/** Logica de la aplicación */



router.isReady().then(async () => {
  window.fake_console('main ','App montada')
  inicializar_dispositivo()
  await self.$getLoc()
  await self.$generar_product_lines()
  app.mount('#app');
  
});

self.$generar_product_lines = async function() {
  try 
  {
    const pl = await this.$http.get('/plines')
    self.$plines = pl.data   
    window.fake_console(self.$plines)
  }   
  catch(error) {
    window.fake_console('Error en el http.get (139): '+ JSON.stringify(error))
 }
}


self.$goHome = function() {
  const curr_time = Date.now()
  self.$router.replace(`/home?${curr_time}`)
}
const inicializar_dispositivo = async function(){
  try 
  {
    const device_id = await Device.getId()
    self.$dev_id = device_id.uuid
    window.fake_console(device_id.uuid)
    self.$http.defaults.headers["Key"] = device_id.uuid
    return true
  }
  catch(ex) {
    return false
  }              
}

self.$getLoc = function () {
  try
    { 
      if ( navigator.geolocation ) { 
        navigator.geolocation.getCurrentPosition( setCurrentPosition, positionError, { 
              enableHighAccuracy: true, 
              timeout: 15000, 
              maximumAge: 0 
          } );
        } 
    }
  catch(e) {
    return false
  }
  }

const setCurrentPosition = async function ( coordinates ) {      
    try {
      const params =  {
        'lat': coordinates.coords.latitude ,
        'lon': coordinates.coords.longitude ,
        'acc': coordinates.coords.accuracy
        }
      const d = await self.$http.get('get-my-pdv', {params})
      const pdv = d.data[0]
      savePos(pdv.p)
      self.$pdv = pdv.p
      self.$dist = pdv.dist
      eventBus().emitter.emit('distance-checked', {'pdv':pdv.p, 'dist':pdv.dist})
      window.fake_console(self.$pdv, self.$dist)
      return true 
    }
    catch(ex){
      alert(JSON.stringify(ex))
      window.fake_console('L166 Error: '+JSON.stringify(ex))
      return false 
    }

  }



 const  positionError = function ( error ) { 
    alert(error)
        switch ( error.code ) { 
            case error.PERMISSION_DENIED: 
                alert( "User denied the request for Geolocation." ); 
                break; 
    
            case error.POSITION_UNAVAILABLE: 
                alert( "Location information is unavailable." ); 
                break; 
            case error.TIMEOUT: 
                alert( "The request to get user location timed out." ); 
                break; 
            case error.UNKNOWN_ERROR: 
                alert( "An unknown error occurred." ); 
                break; 
        }
    return false
  } 




eventBus().emitter.on('device',(dev)=>{ self.$device=dev});

eventBus().emitter.on('location',()=>{ 
  self.$pdv = '133'
  self.$dist = 0
});


eventBus().emitter.on('deeplink',(dl)=>{ 
  window.fake_console('en el evento '+dl)
  self.$deeplink=dl
});

/** Variables globales que se utilizan en el comportamiento de la app */
eventBus().emitter.on('logged-in',(d)=>{ 
  window.fake_console('main ',d)
  self.$expires = d.ex
  self.$http.defaults.headers['Authorization'] = 'Bearer '+d.tk
  saveExpire(d.ex, d.tk)
});


/*
eventBus().emitter.on('update-filters',(fl)=>{ 
  window.fake_console('en el evento update-filters '+fl)
  self.$current_filters=fl
});
*/