<template>
    <ion-list-header color="secondary" lines="full">
        Selecciona unidades ({{sku}})
    </ion-list-header>
    <ion-radio-group @ionChange="cambio($event)">
        <ion-list lines="full" mode="md" inset="true">
            <ion-item  v-for="(ik,index ) in rango" v-bind:key="index">
                <ion-label> {{us*(ik+1)}}  {{um}}</ion-label>
                <ion-radio slot="start" :value="us*(ik+1)"></ion-radio>
            </ion-item>
        </ion-list>
    </ion-radio-group>

</template>

<script>
    import {   
        IonList,IonItem,IonLabel,IonRadio,IonRadioGroup, IonListHeader
    } from '@ionic/vue';
    import eventBus from "@/event-bus";
    import { defineComponent } from 'vue';
    export default defineComponent({
            name : "SelectUnitsComponent",
            components :  {   IonList,IonItem,IonLabel,IonRadio, IonRadioGroup,IonListHeader }, 
            data()  {
                return {
                    rango : [...Array(40).keys()]
                }
            },
            props: ['us','um','sku'], 
            methods :{
                cambio (e) {
                    eventBus().emitter.emit('seleccionado', {"sku" :this.sku , "un" : e.detail.value})
                }
            }
    })
</script>

<style scoped>
    ion-list-header {
        font-size: larger !important; 
        font-weight: bold !important;
    }
    ion-list {
        padding-top:0px !important;
    }
    /**añado esto */

    ion-radio-group{width: 100% !important;}
</style>